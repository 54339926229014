import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { addContact, updateContact } from '../../services/contactServices';
import _ from 'lodash';
import { showToast } from '../../utils/toastUtils';
import { isSuperAdmin, isAccountAdmin } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import { PatternFormat } from 'react-number-format';
import { removePhoneFormat } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { PROVINCE } from '../../constants';
import { addContactData, setContactData } from '../../redux/contact/contactReducer';
import { handlePostalCodeChange, formatPostalCode } from '../../utils/formatUtils';

function ContactForm({
  isFormEdit = false,
  selectedContact = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton
}) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, setError, clearErrors, watch, reset, setFocus, control, formState: { errors, isDirty } } = useForm();
  const userType = useSelector((state) => state.auth.user.userType);
  const customerState = useSelector((state) => state.customer);
  const customerIdWatch = watch("customerId");

  useEffect(() => {
    if (isFormEdit) {
      setValue("province", parseInt(selectedContact['province']));
      setValue("customerId", parseInt(selectedContact['customerId']));
      setValue("active", selectedContact['active']);
      setValue("phone", selectedContact['phone']);
      setValue("fax", selectedContact['fax']);
    } else {
      reset();
    }

  }, [selectedContact["contactId"]])

  // useStates
  const [customerList, setCustomerList] = useState([]);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    setCustomerList(customerState.data);
  }, [customerState.data])

  useEffect(() => {
    clearErrors();
    reset();
    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("customerId");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    setIsFormDirty(isDirty)
  }, [isDirty])

  useEffect(() => {
    const accountIdFilter = _.filter(customerList, c => c.customerId === customerIdWatch);
    const firstItem = _.first(accountIdFilter);

    setValue("accountId", firstItem?.accountId);
  }, [customerIdWatch])

  const getActiveDefaultValue = () => {
    if (selectedContact.length <= 0) {
      return 'true';
    }

    return selectedContact.active ? 'true' : '';
  }

  const onSubmit = async (payload) => {

    _.forOwn(payload, (value, key) => {
      if (typeof value === 'string') {
        payload[key] = value.trim();
      }
    });

    if (payload.postalCode) {
      payload.postalCode = payload.postalCode.replace(/\s+/g, ''); // Remove all spaces
    }

    let response = null;

    _.set(payload, 'phone', removePhoneFormat(payload.phone));
    _.set(payload, 'cell', removePhoneFormat(payload.cell));

    if (payload.phone === "") {
      setError("phone", { message: "Phone is required" });
      return;
    }

    if (isFormEdit) {
      _.set(payload, 'contactId', selectedContact.contactId);
      response = await updateContact(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully updated' : 'Failed to update'} contact.`
      });
    } else {
      _.set(payload, 'contactId', 0);
      response = await addContact(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully added' : 'Failed to add'} contact.`
      });
    }

    if (response.ok) {
      const contactData = await response.json();
      dispatch(isFormEdit ? setContactData(contactData) : addContactData(contactData));
      toggleFormDisplay();
      focusOnOpenOrCloseButton();
    }
  };

  return (
    <div className='content-section-container'>
      <div className='d-flex flex-row align-items-center mb-3'>
        <div className='form-title me-auto'>
          {`${isFormEdit ? 'Update' : 'Add'} Contact`}
        </div>
        <button className="btn btn-primary" form='contact-form' type="submit" tabIndex={13}>{isFormEdit ? 'Update' : 'Save'}</button>
      </div>
      <form className="row form-container" id="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-12 col-sm-4 mb-2">
          <label htmlFor="customerId" className="form-label">Customer</label>
          <select className="form-select" id="customerId" tabIndex={1}
            defaultValue={isFormEdit ? selectedContact?.customerId || "" : ""}
            {...register("customerId", { valueAsNumber: true, required: "Customer is required" })}>
            <option key="none" value="" hidden>Select Customer</option>
            {customerList.map((customer, index) =>
              <option key={index} value={customer.customerId}>{customer.customerName}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.customerId && errors.customerId.message}
          </small>
        </div>
        <div className="col-12 my-2">
          <hr></hr>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="firstName" className="form-label">First Name</label>
          <input type="text" className="form-control" id="firstName" tabIndex={2}
            defaultValue={isFormEdit ? selectedContact?.firstName || '' : ''}
            {...register("firstName", { required: "First Name is required" })}
          />
          <small className='form-error-message'>
            {errors?.firstName && errors.firstName.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="lastName" className="form-label">Last Name</label>
          <input type="text" className="form-control" id="lastName" tabIndex={3}
            defaultValue={isFormEdit ? selectedContact?.lastName || '' : ''}
            {...register("lastName", { required: "Last Name is required" })}
          />
          <small className='form-error-message'>
            {errors?.lastName && errors.lastName.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="email" className="form-label">Email</label>
          <input type="email" className="form-control" id="email" tabIndex={4}
            defaultValue={isFormEdit ? selectedContact?.email || '' : ''}
            autoComplete='on'
            {...register("email", { required: "Email is required" })}
          />
          <small className='form-error-message'>
            {errors?.email && errors.email.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="phone" className="form-label">Phone</label>
          <Controller
            control={control}
            name='phone'
            register={register}
            rules={{ required: "Phone is required" }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <PatternFormat value={value === undefined ? '' : value} onChange={onChange} onBlur={onBlur} tabIndex={5} getInputRef={ref} className='form-control' format="(###) ###-####" id="phone" mask="_" allowEmptyFormatting autoComplete='on' />
            )}
          />
          <small className='form-error-message'>
            {errors?.phone && errors.phone.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="mobile" className="form-label">Mobile</label>
          <Controller
            control={control}
            name='cell'
            register={register}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <PatternFormat defaultValue={isFormEdit ? selectedContact?.cell || '' : ''} value={isFormEdit ? selectedContact?.cell || '' : ''} onChange={onChange} onBlur={onBlur} tabIndex={6} className='form-control' format="(###) ###-####" id="mobile" mask="_" allowEmptyFormatting />
            )}
          />
          <small className='form-error-message'>
            {errors?.cell && errors.cell.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="notes" className="form-label">Notes</label>
          <input type="text" className="form-control" id="notes" tabIndex={7}
            defaultValue={isFormEdit ? selectedContact?.notes || '' : ''}
            {...register("notes")}
          />
          <small className='form-error-message'>
            {errors?.notes && errors.notes.message}
          </small>
        </div>
        <div className="col-12 my-2">
          <hr></hr>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="street" className="form-label">Street</label>
          <input type="text" className="form-control" id="street" tabIndex={8}
            defaultValue={isFormEdit ? selectedContact?.street || '' : ''}
            {...register("street")}
          />
          <small className='form-error-message'>
            {errors?.street && errors.street.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="city" className="form-label">City</label>
          <input type="text" className="form-control" id="city" tabIndex={9}
            defaultValue={isFormEdit ? selectedContact?.city || '' : ''}
            {...register("city")}
          />
          <small className='form-error-message'>
            {errors?.city && errors.city.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="province" className="form-label">Province</label>
          <select className="form-select" id="province" tabIndex={10}
            defaultValue={selectedContact.province || ''}
            {...register("province", { valueAsNumber: true, })}>
            <option key="none" value="" hidden>Select Province</option>
            {PROVINCE.map((prov, index) =>
              <option key={index} value={prov.value}>{prov.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.province && errors.province.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="postalCode" className="form-label">Postal Code</label>
          <input
            type="text"
            className="form-control"
            id="postalCode"
            tabIndex={11}
            defaultValue={isFormEdit ? formatPostalCode(selectedContact?.postalCode || '') : ''}
            {...register("postalCode", { required: "Postal Code is required", maxLength: 7 })}
            onChange={handlePostalCodeChange}
          />
          <small className="form-error-message">
            {errors?.postalCode && errors.postalCode.message}
          </small>
        </div>
        {
          (isSuperAdmin(userType) || isAccountAdmin(userType)) &&
          <div className="col-12 mb-2 mt-3">
            <div className="form-check">
              <input className="form-check-input align-middle" type="checkbox" value="" id="active"
                defaultChecked={getActiveDefaultValue} tabIndex={12}
                {...register("active")}
              />
              <label className="form-check-label align-middle " htmlFor="active"> Active</label>
              <small className='form-error-message'>
                {errors?.active && errors.active.message}
              </small>
            </div>
          </div>
        }
      </form>
      <ModalRender modal={modal} handleSubmit={handleSubmit} onSubmit={onSubmit} setModal={setModal} toggle={toggle} isFormEdit={isFormEdit} formType={"contact"} toggleFormDisplay={toggleFormDisplay} />
    </div>
  )
}

export default ContactForm
