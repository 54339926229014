import { 
  BadgeOutlined,
  CreditCard,
  DashboardOutlined,
  DescriptionOutlined,
  PaidOutlined,
  PaymentsOutlined,
  PeopleOutlineOutlined,
  PhoneOutlined,
  RequestQuoteOutlined,
  SettingsOutlined,
  SwitchAccountOutlined,
  BusinessCenterOutlined,
  PaymentOutlined,
  LibraryBooksOutlined,
  ReceiptLongOutlined,
  ImageOutlined,
  AccountBoxOutlined
} from "@mui/icons-material";

export const USER_TYPES = {
  SUPER_ADMIN: 1,
  ABM_USER: 2,
  ACCOUNT_ADMIN: 3,
  ACCOUNT_USER: 4
};

export const TAX_TYPES = [
  {
    label: 'None',
    value: 0
  },
  {
    label: 'GST',
    value: 1
  },
  {
    label: 'PST',
    value: 2
  },
  {
    label: 'HST',
    value: 3
  },
  {
    label: 'GST/PST',
    value: 4
  },
  {
    label: 'Other',
    value: 5
  },
];

export const PAYMENT_TYPES = [
  {
    label: 'On Credit',
    value: 0
  },
  {
    label: 'Credit Card',
    value: 1
  },
  {
    label: 'Cheque',
    value: 2
  },
  {
    label: 'Cash',
    value: 3
  },
];

export const MENU_ITEMS = [
  {
    label: 'Dashboard',
    icon: <DashboardOutlined />,
    link: '/dashboard',
    disabled: false
  },
  {
    label: 'Accounts',
    icon: <SwitchAccountOutlined />,
    link: '/accounts',
    disabled: false
  },
  {
    label: 'Customers',
    icon: <PeopleOutlineOutlined />,
    link: '/customers',
    disabled: false
  },
  {
    label: 'Contacts',
    icon: <PhoneOutlined />,
    link: '/contacts',
    disabled: false
  },
  {
    label: 'Quotes',
    icon: <DescriptionOutlined />,
    link: '/quotes',
    disabled: false
  },
  {
    label: 'Invoices',
    icon: <RequestQuoteOutlined />,
    link: '/invoices',
    disabled: false
  },
  {
    label: 'Payments',
    icon: <CreditCard />,
    link: '/payments',
    subMenuItems: [
      {
        label: 'Invoices',
        link: '/payments',
        disabled: false
      },
      {
        label: 'Expenses',
        link: '/expense-payments',
        disabled: false
      }
    ],
    disabled: false
  },
  {
    label: 'Employees',
    icon: <BadgeOutlined />,
    link: '/employees',
    disabled: false
  },
  {
    label: 'Payroll',
    icon: <PaidOutlined />,
    link: '/payroll-runs',
    disabled: false
  },
  // {
  //   label: 'Expenses',
  //   icon: <PaymentsOutlined />,
  //   link: '/expenses',
  //   disabled: false
  // },
  {
    label: 'Expenses',
    icon: <PaymentsOutlined />,
    link: '/expenses',
    subMenuItems: [
      {
        label: 'Expenses',
        link: '/expenses/default',
        disabled: false
      },
      {
        label: 'Quick Entry',
        link: '/expenses/quick-entry',
        disabled: false
      }
    ],
    disabled: false
  },
  // {
  //   label: 'old-Expenses',
  //   icon: <PaymentsOutlined />,
  //   link: '/old-expenses',
  //   disabled: false
  // },
  {
    label: 'Settings',
    icon: <SettingsOutlined />,
    link: '/settings',
    disabled: false
  }
];

export const SETTINGS_ITEMS = [
  {
    label: 'Accounting Settings',
    // icon: 'fa-solid fa-briefcase',
    icon: <BusinessCenterOutlined />,
    link: '/settings/accounting-settings',
    authorizationCheck: true
  },
  {
    label: 'Payroll Settings',
    icon: <PaidOutlined />,
    link: '/settings/payroll-settings',
    authorizationCheck: true
  },
  {
    label: 'Image Settings',
    icon: <ImageOutlined/>,
    link: '/settings/image-settings',
    authorizationCheck: false,
  },
  {
    label: 'Expense Categories',
    icon: <PaymentOutlined/>,
    link: '/settings/expense-categories',
    authorizationCheck: false
  },
  {
    label: 'GL Codes',
    icon: <LibraryBooksOutlined/>,
    link: '/settings/gl-codes',
    authorizationCheck: true
  },
  {
    label: 'Report Templates',
    icon: <ReceiptLongOutlined/>,
    link: '/settings/report-templates',
    authorizationCheck: false
  },
  {
    label: 'Account Settings',
    icon: <AccountBoxOutlined/>,
    link: '/settings/account-settings',
    authorizationCheck: true
  },
];

export const PAY_FREQUENCY = [
  {
    label: 'Weekly',
    value: 1
  },
  {
    label: 'Bi-weekly',
    value: 2
  },
  {
    label: 'Semi-monthly',
    value: 3
  },
  {
    label: 'Monthly',
    value: 4
  },
];

export const VACATION_TYPE = [
  {
    label: 'Percent',
    value: 0
  },
  {
    label: 'Days',
    value: 1
  },
];

export const PAY_METHOD_TYPE = [
  {
    label: 'Salary',
    value: 0
  },
  {
    label: 'Hours',
    value: 1
  },
  {
    label: 'Miles',
    value: 2
  },
  {
    label: 'Other',
    value: 3
  },
  {
    label: 'Advance',
    value: 4
  },
  {
    label: 'Reimbursement',
    value: 5
  },
];

export const CURRENCY_TYPE = [
  {
    label: 'Canada',
    value: 1
  },
  {
    label: 'US',
    value: 2
  },
];

export const PROVINCE = [
  {
    label: 'AB',
    value: 0
  },
  {
    label: 'BC',
    value: 1
  },
  {
    label: 'MB',
    value: 2
  },
  {
    label: 'NB',
    value: 3
  },
  {
    label: 'NL',
    value: 4
  },
  {
    label: 'NT',
    value: 5
  },
  {
    label: 'NS',
    value: 6
  },
  {
    label: 'NU',
    value: 7
  },
  {
    label: 'ON',
    value: 8
  },
  {
    label: 'PE',
    value: 9
  },
  {
    label: 'QC',
    value: 10
  },
  {
    label: 'SK',
    value: 11
  },
  {
    label: 'YT',
    value: 12
  },
];

export const TD1_PROVINCE = [
  {
    label: 'Claim Code 0 (No claim amount)',
    value: 0
  },
  {
    label: 'Claim Code 1 (Basic claim)',
    value: 1
  },
  {
    label: 'Claim Code 2',
    value: 2
  },
  {
    label: 'Claim Code 3',
    value: 3
  },
  {
    label: 'Claim Code 4',
    value: 4
  },
  {
    label: 'Claim Code 5',
    value: 5
  },
  {
    label: 'Claim Code 6',
    value: 6
  },
  {
    label: 'Claim Code 7',
    value: 7
  },
  {
    label: 'Claim Code 8',
    value: 8
  },
  {
    label: 'Claim Code 9',
    value: 9
  },
  {
    label: 'Claim Code 10',
    value: 10
  },
];

export const TD1_PROVINCE_NB = [
  {
    label: 'Claim Code 0 (No claim amount)',
    value: 0
  },
  {
    label: 'Claim Code 1 (Basic claim)',
    value: 1
  },
  {
    label: 'Claim Code 2',
    value: 2
  },
  {
    label: 'Claim Code 3',
    value: 3
  },
  {
    label: 'Claim Code 4',
    value: 4
  },
  {
    label: 'Claim Code 5',
    value: 5
  },
  {
    label: 'Claim Code 6',
    value: 6
  },
  {
    label: 'Claim Code 7',
    value: 7
  },
  {
    label: 'Claim Code 8',
    value: 8
  },
];

export const TD1_PROVINCE_QC = [
  {
    label: 'Claim Code 0',
    value: 0
  },
];

export const TD2_FEDERAL = [
  {
    label: 'Claim Code 0 (No claim amount)',
    value: 0
  },
  {
    label: 'Claim Code 1 (Basic claim)',
    value: 1
  },
  {
    label: 'Claim Code 2',
    value: 2
  },
  {
    label: 'Claim Code 3',
    value: 3
  },
  {
    label: 'Claim Code 4',
    value: 4
  },
  {
    label: 'Claim Code 5',
    value: 5
  },
  {
    label: 'Claim Code 6',
    value: 6
  },
  {
    label: 'Claim Code 7',
    value: 7
  },
  {
    label: 'Claim Code 8',
    value: 8
  },
  {
    label: 'Claim Code 9',
    value: 9
  },
  {
    label: 'Claim Code 10',
    value: 10
  },
];

export const REPORT_TYPES = [
  {
    label: 'Quote',
    value: 1
  },
  {
    label: 'Invoices',
    value: 2
  },
  {
    label: 'Payroll Run',
    value: 3
  },
  {
    label: 'Pay Stub',
    value: 4
  },
  {
    label: 'Expense',
    value: 5
  },
  {
    label: 'Expense Run',
    value: 6
  },
  {
    label: 'Payment',
    value: 7
  },
  {
    label: 'Payment Run',
    value: 8
  },
];

export const QUOTE_STATUS = [
  {
    label: 'Draft',
    value: 0
  },
  {
    label: 'Approved',
    value: 1
  },
  {
    label: 'Archived',
    value: 2
  }
];

export const QUOTE_REPORTS = [
  { label: 'All Quotes' },
  { label: 'Unsettled Quotes' },
  { label: 'Approved Quotes' },
  { label: 'Archived Quotes' }
]

export const INVOICE_REPORTS = [
  { label: 'All Invoices' },
  { label: 'Outstanding Invoices' },
  { label: 'Paid Invoices' }
]

export const INCOME_REPORTS = [
  { label: 'Income' },
]

export const ACCOUNTS_RECEIVABLE_REPORTS = [
  { label: 'A/R Aging' },
  { label: 'Detailed A/R Aging' },
]

export const ACCOUNTS_PAYABLE_REPORTS = [
  { label: 'A/P Aging' },
]

export const PAYROLL_REPORTS = [
  { label: 'PD7A' },
  { label: 'Employee Paystubs' },
  { label: 'T4' },
  { label: 'ROE' },
]

export const EXPENSE_ITEM_TYPES = [
  {
    label: 'expense-item',
    value: 1
  },
  {
    label: 'product-material-item',
    value: 2
  },
  {
    label: 'labour-item',
    value: 3
  }
]

export const PAYROLL_RUN_TYPES = [
  {
    label: 'Periodic',
    value: 1
  },
  {
    label: 'One-Off',
    value: 2
  },
]

export const CUSTOMER_TYPES = [
  {
    label: 'Customer',
    value: 0
  },
  {
    label: 'Vendor',
    value: 1
  },
  {
    label: 'Both',
    value: 2
  },
]