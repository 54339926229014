import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { addAccount, updateAccount } from '../../services/accountServices';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import { showToast } from '../../utils/toastUtils';
import { isSuperAdmin, isAccountAdmin, isAccountUser, isABMUser } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import { PatternFormat } from 'react-number-format';
import { removePhoneFormat } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { PROVINCE, TAX_TYPES } from '../../constants';
import { addAccountData, setAccountData } from '../../redux/account/accountReducer';
import { handlePostalCodeChange, formatPostalCode } from '../../utils/formatUtils';

function AccountForm({
  isFormEdit = false,
  selectedAccount = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton,
  openOrCloseButtonRef
}) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, setError, clearErrors, reset, setFocus, control, formState: { errors, isDirty } } = useForm();
  const [province, setProvince] = useState("")
  const [defaultTax, setDefaultTax] = useState("")
  const userType = useSelector((state) => state.auth.user.userType);

  useEffect(() => {
    if (isFormEdit) {
      setValue("accountName", selectedAccount["accountName"]);
      setValue("taxId", selectedAccount["taxId"]);
      setValue("notes", selectedAccount["notes"]);
      setValue("addressLine1", selectedAccount["addressLine1"]);
      setValue("addressLine2", selectedAccount["addressLine2"]);
      setValue("city", selectedAccount["city"]);
      // setValue("postalCode", selectedAccount["postalCode"]);
      setProvince(parseInt(selectedAccount['province']));
      setDefaultTax(parseInt(selectedAccount['defaultTaxCode']));
      setValue("province", parseInt(selectedAccount['province']));
      setValue("defaultTaxCode", parseInt(selectedAccount['defaultTaxCode']));
      setValue("active", selectedAccount['active']);
      setValue("phone", selectedAccount['phone']);
      setValue("fax", selectedAccount['fax']);
      setValue("cell", selectedAccount['cell']);
    } else {
      setProvince("");
      setDefaultTax("");
      reset();
    }

  }, [selectedAccount["accountId"]])

  // form values
  setValue('accountId', 0);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    if (isFormHidden) {
      setValue("phone", "")
    }
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("accountName");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty])

  const getActiveDefaultValue = () => {
    if (selectedAccount.length <= 0) {
      return 'true';
    }

    return selectedAccount.active ? 'true' : '';
  }

  const onSubmit = async (payload) => {

    _.forOwn(payload, (value, key) => {
      if (typeof value === 'string') {
        payload[key] = value.trim();
      }
    });
    
    if (payload.postalCode) {
      payload.postalCode = payload.postalCode.replace(/\s+/g, ''); // Remove all spaces
    }

    let response = null;

    _.set(payload, 'phone', removePhoneFormat(payload.phone));
    _.set(payload, 'fax', removePhoneFormat(payload.fax));
    _.set(payload, 'cell', removePhoneFormat(payload.cell));

    if (payload.phone === "") {
      setError("phone", { message: "Phone is required" });
      return;
    }

    if (isFormEdit) {
      _.set(payload, 'accountId', selectedAccount.accountId);
      response = await updateAccount(payload);

      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully updated' : 'Failed to update'} account.`
      });
    } else {
      _.set(payload, 'accountId', 0);
      response = await addAccount(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully added' : 'Failed to add'} account.`
      });
    }

    if (response.ok) {
      const accountData = await response.json();
      dispatch(isFormEdit ? setAccountData(accountData) : addAccountData(accountData));
      if (!isAccountUser(userType) && !isAccountAdmin(userType)) {
        toggleFormDisplay();
        reset();
        focusOnOpenOrCloseButton()

      }
      else {
        setFocus("accountName");
      }

      if (isDirty) {
        setModal(false);
      }
      setIsFormDirty(false);

    }
  }

  return (
    <div className='content-section-container'>
      <div className='d-flex flex-row align-items-center mb-5'>
        <div className='form-title me-auto'>
          {`${isFormEdit ? 'Update' : 'Add'} Account`}
        </div>
        <button className="btn btn-primary" form='account-form' type="submit" tabIndex={15}>Save</button>
      </div>
      <form className="row form-container" id="account-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="name" className="form-label">Name</label>
          <input type="text" className="form-control" id="name" tabIndex={1}
            defaultValue={isFormEdit ? selectedAccount?.accountName || '' : ''}
            autoComplete='on'
            {...register("accountName", { required: "Name is required" })}
          />
          <small className='form-error-message'>
            {errors?.accountName && errors.accountName.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="defaultTaxCode" className="form-label">Default Tax Code:</label>
          <select className="form-select" id="defaultTaxCode" value={defaultTax} tabIndex={2}
            {...register("defaultTaxCode", { valueAsNumber: true, onChange: (e) => setDefaultTax(e.target.value), required: "Default Tax Code is required" })}
          >
            <option key="none" value="" hidden>Default Tax Code</option>
            {TAX_TYPES.map((tax, index) =>
              <option key={index} value={tax.value}>{tax.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.defaultTaxCode && errors.defaultTaxCode.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="taxId" className="form-label">Tax ID:</label>
          <input type="text" className="form-control" id="taxId" tabIndex={3}
            defaultValue={isFormEdit ? selectedAccount?.taxId || '' : ''}
            {...register("taxId", { required: "Tax ID is required" })}
          />
          <small className='form-error-message'>
            {errors?.taxId && errors.taxId.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="phone" className="form-label">Phone</label>
          <Controller
            control={control}
            name='phone'
            register={register}
            rules={{ required: "Phone is required" }}

            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <PatternFormat
                defaultValue={value === undefined ? '' : value}
                value={value === undefined ? '' : value}
                onChange={onChange}
                tabIndex={4}
                onBlur={onBlur}
                getInputRef={ref}
                className='form-control'
                format="(###) ###-####"
                id="phone"
                mask="_"
                allowEmptyFormatting autoComplete='on'
              />
            )}
          />
          <small className='form-error-message'>
            {errors?.phone && errors.phone.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="fax" className="form-label">Fax</label>
          <Controller
            control={control}
            name='fax'
            register={register}

            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <PatternFormat
                defaultValue={isFormEdit ? selectedAccount?.fax || '' : ''}
                value={isFormEdit ? selectedAccount?.fax || '' : ''}
                onChange={onChange}
                tabIndex={5}
                onBlur={onBlur}
                className='form-control'
                format="(###) ###-####"
                id="fax"
                mask="_"
                allowEmptyFormatting
              />
            )}
          />
          <small className='form-error-message'>
            {errors?.fax && errors.fax.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="mobile" className="form-label">Mobile</label>
          <Controller
            control={control}
            name='cell'
            register={register}

            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <PatternFormat
                defaultValue={isFormEdit ? selectedAccount?.cell || '' : ''}
                value={isFormEdit ? selectedAccount?.cell || '' : ''}
                onChange={onChange}
                tabIndex={6}
                onBlur={onBlur}
                className='form-control'
                format="(###) ###-####"
                id="mobile"
                mask="_"
                allowEmptyFormatting
              />
            )}
          />
          <small className='form-error-message'>
            {errors?.cell && errors.cell.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="notes" className="form-label">Notes</label>
          <input type="text" className="form-control" id="notes" tabIndex={7}
            defaultValue={isFormEdit ? selectedAccount?.notes || '' : ''}
            {...register("notes")}
          />
          <small className='form-error-message'>
            {errors?.notes && errors.notes.message}
          </small>
        </div>
        <div className="col-12 my-2">
          <hr></hr>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="addressLine1" className="form-label">Address Line 1</label>
          <input type="text" className="form-control" id="addressLine1" tabIndex={8}
            defaultValue={isFormEdit ? selectedAccount?.addressLine1 || '' : ''}
            {...register("addressLine1", { required: "Address Line 1 is required" })}
          />
          <small className='form-error-message'>
            {errors?.addressLine1 && errors.addressLine1.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="addressLine2" className="form-label">Address Line 2</label>
          <input type="text" className="form-control" id="addressLine2" tabIndex={9}
            defaultValue={isFormEdit ? selectedAccount?.addressLine2 || '' : ''}
            {...register("addressLine2")}
          />
          <small className='form-error-message'>
            {errors?.addressLine2 && errors.addressLine2.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="city" className="form-label">City</label>
          <input type="text" className="form-control" id="city" tabIndex={10}
            defaultValue={isFormEdit ? selectedAccount?.city || '' : ''}
            {...register("city", { required: "City is required" })}
          />
          <small className='form-error-message'>
            {errors?.city && errors.city.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="province" className="form-label">Province:</label>
          <select className="form-select" id="province" value={province} tabIndex={11} {...register("province", { valueAsNumber: true, onChange: (e) => setProvince(e.target.value), required: "Province is required" })}>
            <option key="none" value="" hidden>Province</option>
            {PROVINCE.map((prov, index) =>
              <option key={index} value={prov.value}>{prov.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.province && errors.province.message}
          </small>
        </div>
        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="postalCode" className="form-label">Postal Code</label>
          <input
            type="text"
            className="form-control"
            id="postalCode"
            tabIndex={12}
            defaultValue={isFormEdit ? formatPostalCode(selectedAccount?.postalCode || '') : ''}
            {...register("postalCode", { required: "Postal Code is required", maxLength: 7 })}
            onChange={handlePostalCodeChange}
          />
          <small className="form-error-message">
            {errors?.postalCode && errors.postalCode.message}
          </small>
        </div>
        {
          (isSuperAdmin(userType) || isAccountAdmin(userType)) &&
          <div className="col-12 mb-2 mt-3">
            <div className="form-check">
              <input className="form-check-input align-middle" type="checkbox" value="" id="active" tabIndex={14}
                defaultChecked={getActiveDefaultValue}
                {...register("active")}
              />
              <label className="form-check-label align-middle " htmlFor="active"> Active</label>
              <small className='form-error-message'>
                {errors?.active && errors.active.message}
              </small>
            </div>
          </div>
        }

      </form>
      <ModalRender modal={modal} handleSubmit={handleSubmit} onSubmit={onSubmit} setModal={setModal} toggle={toggle} isFormEdit={isFormEdit} formType={"account"} toggleFormDisplay={toggleFormDisplay} />
    </div>
  )
}

export default AccountForm
