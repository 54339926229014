import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import CustomerForm from "./CustomerForm";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import NameAndEmailRenderer from "../Templates/CellRenderers/Contacts/NameAndEmailRenderer";
import ProfilePictureRenderer from "../Templates/CellRenderers/Commons/ProfilePictureRenderer";
import PhoneAndMobileRenderer from "../Templates/CellRenderers/Commons/PhoneAndMobileRenderer";
import EditFormRenderer from "../Templates/CellRenderers/Commons/EditFormRenderer";
import _ from "lodash";
import { fetchCustomersByAccount } from "../../services/customerServices";
import { enumValueFormatter, formatPhoneNumber } from "../../utils/formatUtils";
import { useDispatch, useSelector } from "react-redux";
import { setCustomersListData } from "../../redux/customer/customerReducer";
import { CUSTOMER_TYPES } from "../../constants";

function Customers() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const customerState = useSelector((state) => state.customer);

  const toggleFormDisplay = (isEdit = false, customerId = null) => {
    if (isFormHidden) {
      setSelectedCustomerId(customerId);
    } else {
      setSelectedCustomerId(null);
    }
    if (modal) {
      setModal(!modal);
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  };

  const toggleFormCancelDisplay = (isEdit = false, accountId = null) => {
    if (isFormDirty) {
      toggle();
      return;
    }
    toggleFormDisplay();
  };

  const defaultColumnDefs = [
    {
      field: "#",
      width: 50,
      minWidth: 50,
      suppressSizeToFit: true,
      cellClass: "no-borders",
      cellStyle: { fontWeight: "bold" },
    },
    {
      field: "ProfilePicture",
      minWidth: 70,
      headerName: "",
      cellRenderer: ProfilePictureRenderer,
      width: 80,
      suppressSizeToFit: true,
      cellClass: "no-borders",
    },
    {
      field: "Customer Name",
      minWidth: 150,
      cellClass: "no-borders",

      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Customer Type",
      minWidth: 150,
      cellClass: "no-borders",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => {
        return enumValueFormatter(params, CUSTOMER_TYPES) || "None";
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
      suppressMenu: true,
    },
    {
      field: "Phone",
      minWidth: 150,
      cellClass: "no-borders",
      valueFormatter: (params) => {
        return formatPhoneNumber(params.value);
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Mobile",
      minWidth: 150,
      cellClass: "no-borders",
      valueFormatter: (params) => {
        return formatPhoneNumber(params.value);
      },
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Email",
      minWidth: 150,
      cellClass: "no-borders",
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Edit",
      minWidth: 150,
      headerName: "",
      cellClass: "d-flex flex-row-reverse",
      autoHeight: true,
      cellRenderer: EditFormRenderer,
      cellRendererParams: { toggleFormDisplay },
    },
  ];

  const applyCustomFilter = (header, value, type = null) => {
    if (value === null) {
      gridRef.current.api.setFilterModel({
        [header]: null,
      });
    } else {
      gridRef.current.api.setFilterModel({
        [header]: {
          type: type,
          filter: value,
        },
      });
    }

    gridRef.current.api.onFilterChanged();
  };

  const columnDefsWithRow = [
    {
      field: "#",
      width: 50,
      minWidth: 50,
      suppressSizeToFit: true,
      cellClass: "no-borders",
      cellStyle: { fontWeight: "bold" },
    },
    {
      field: "ProfilePicture",
      minWidth: 70,
      headerName: "",
      cellRenderer: ProfilePictureRenderer,
      width: 80,
      suppressSizeToFit: true,
      cellClass: "no-borders",
    },
    {
      field: "NameAndEmail",
      minWidth: 150,
      headerName: "Customer Name",
      cellRenderer: NameAndEmailRenderer,
    },
    {
      field: "PhoneAndMobile",
      minWidth: 150,
      headerName: "Contacts",
      cellRenderer: PhoneAndMobileRenderer,
    },
  ];

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(defaultColumnDefs);
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // useEffects
  useEffect(() => {
    init();
    focusOnOpenOrCloseButton();
  }, []);

  useEffect(() => {
    setCustomerList(customerState.data);
  }, [customerState.data]);

  useEffect(() => {
    setColumnDefs(isFormHidden ? defaultColumnDefs : columnDefsWithRow);
  }, [isFormHidden]);

  useEffect(() => {
    let data = [];
    _.each(customerList, (customer, index) => {
      data.push({
        "#": index + 1,
        ProfilePicture: { url: `images/profile-picture-fallback.png` },
        "Customer Name": customer.customerName,
        "Customer Type": customer.customerType,
        Email: customer.email,
        Phone: customer.phone,
        Mobile: customer.cell,
        PhoneAndMobile: { phone: customer.phone, mobile: customer.cell },
        NameAndEmail: { name: customer.customerName, email: customer.email },
        Edit: { id: customer.customerId },
      });
    });
    setRowData(data);
  }, [customerList]);

  useEffect(() => {
    fillSelectedCustomer(selectedCustomerId);
  }, [selectedCustomerId]);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const init = async () => {
    const response = await getCustomersData();
    dispatch(setCustomersListData(response));
  };

  const getCustomersData = async () => {
    return await fetchCustomersByAccount(
      userType,
      selectedAccountState.accountId,
      accountId
    );
  };

  const fillSelectedCustomer = (id) => {
    const customer = _.find(customerList, { customerId: id });
    setSelectedCustomer(customer);
  };

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const focusOnOpenOrCloseButton = () => {
    openOrCloseButtonRef.current.focus();
  };

  return (
    <div>
      <div className="d-flex flex-row align-items-center content-header-container ">
        <div className="me-auto">
          <ContentHeader
            title="Customers"
            dataCount={customerList.length}
            filterData={filterData}
            showBalanceFilter={isFormHidden}
            applyCustomFilter={applyCustomFilter}
          />
        </div>
        <button
          className={`btn btn-lg ${
            isFormHidden ? "btn-primary" : "btn-secondary"
          }`}
          type="submit"
          onClick={() => toggleFormCancelDisplay()}
          ref={openOrCloseButtonRef}
        >
          {isFormHidden ? "Add Customer" : "Close"}
        </button>
      </div>
      <div className="content-body-container row">
        <div
          className={`${
            isFormHidden ? "col-12" : "col-12 col-lg-5 min-vh-25"
          } mb-2`}
        >
          <div className={`ag-theme-alpine content-section-container`}>
            <AgGridReact
              className="no-header"
              rowData={rowData}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onColumnSizeChanged={onColumnsSizeChanged}
              onGridSizeChanged={onGridSizeChanged}
              rowHeight={70}
            ></AgGridReact>
          </div>
        </div>
        <div className={`col-12 col-lg-7 ${isFormHidden ? "d-none" : ""}`}>
          <CustomerForm
            isFormEdit={isFormEdit}
            selectedCustomer={selectedCustomer}
            isFormHidden={isFormHidden}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>
    </div>
  );
}

export default Customers;
