import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { printPreviewReportWithBase64 } from "../../utils/payrollRunUtils";
import { showToast } from "../../utils/toastUtils";
import ReportsModal from "../Commons/Modals/ReportsModal";
import { useForm, Controller } from "react-hook-form";
import {
  QUOTE_REPORTS,
  INVOICE_REPORTS,
  INCOME_REPORTS,
  ACCOUNTS_RECEIVABLE_REPORTS,
  ACCOUNTS_PAYABLE_REPORTS,
  PAYROLL_REPORTS,
} from "../../constants";
import {
  getReportStatus,
  createReport,
  sendReport,
  singleDateCheck,
} from "../../utils/reportUtils";
import moment from "moment";
import EmployeePaystubReport from "../Commons/Modals/EmployeePaystubReport";
import T4Report from "../Commons/Modals/T4ReportModal";
import ROEReport from "../Commons/Modals/ROEReportModal";

function ReportsMenu() {
  const accountId = useSelector((state) => state.auth.user.accountId);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const [mainModal, setMainModal] = useState(false);
  const [employeePaystubModal, setEmployeePaystubModal] = useState(false);
  const [reportLabel, setReportLabel] = useState(null);
  const [T4ReportModal, setT4ReportModal] = useState(null);
  const [ROEReportModal, setROEReportModal] = useState(null);

  var [showUserField, setShowUserField] = useState(false);

  const [dateRange, setDateRange] = useState([
    new Date(new Date().getFullYear(), 0, 1),
    new Date(),
  ]);

  const printReportList = async (payload) => {
    var reportStartDate =
      payload.reportDateRange != null
        ? moment(payload.reportDateRange[0]).format("YYYY-MM-DD")
        : null;
    var reportEndDate =
      payload.reportDateRange != null
        ? moment(payload.reportDateRange[1]).format("YYYY-MM-DD")
        : null;
    var currentDate = moment().format("YYYY-MM-DD");
    var userId = payload.userId != null ? payload.userId.value : null;
    if (singleDateCheck(reportLabel)) {
      reportStartDate =
        payload.reportStartDate != null
          ? moment(payload.reportStartDate).format("YYYY-MM-DD")
          : null;
      reportEndDate = null;
    }

    const reportPayload = {
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      reportLabel: reportLabel,
      reportStatus: getReportStatus(reportLabel),
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
      currentDate: currentDate,
      userId: userId,
    };

    const response = await createReport(reportLabel, reportPayload);

    try {
      const data = await response.json();

      if (!_.isNil(data.errorMessage)) {
        showToast({
          type: "error",
          message: data.errorMessage,
        });
        return;
      }

      printPreviewReportWithBase64(data.base64);
      setMainModal(false);
      if (!singleDateCheck(reportLabel)) {
        setValue("reportDateRange", dateRange);
      } else {
        setValue("reportStartDate", dateRange[0]);
      }
    } catch (error) {
      showToast({
        type: "error",
        message: "Failed to create report.",
      });
    }
  };

  const toggleMainModal = (label, userFieldVisible = false) => {
    setReportLabel(label);
    setShowUserField(userFieldVisible);
    setMainModal(!mainModal);
    if (mainModal === false) {
      reset();
    }
  };

  const toggleEmployeePaystubModal = (label) => {
    setReportLabel(label);
    setEmployeePaystubModal(!employeePaystubModal);
  };

  const toggleT4ReportModal = (label) => {
    setReportLabel(label);
    setT4ReportModal(!T4ReportModal);
  };

  const toggleROEReportModal = (label) => {
    setReportLabel(label);
    setROEReportModal(!ROEReportModal);
  };

  const disableOnClickAction = (event) => {
    event.stopPropagation();
  };

  const togglePayrollModal = (label) => {
    setReportLabel(label);

    setMainModal(false);
    setEmployeePaystubModal(false);
    setT4ReportModal(false);
    setROEReportModal(false);

    switch (label) {
      case "PD7A":
        setMainModal(true);
        break;
      case "Employee Paystubs":
        setEmployeePaystubModal(true);
        break;
      case "T4":
        setT4ReportModal(true);
        break;
      case "ROE":
        setROEReportModal(true);
        break;
      default:
        setMainModal(true);
    }
  };
  const year = new Date().getFullYear();

  return (
    <>
      <li>
        <button
          className="dropdown-item disabled-action"
          onClick={disableOnClickAction}
        >
          Quote Reports
        </button>
        <ul className="dropdown-menu dropdown-submenu">
          {QUOTE_REPORTS.map((quoteReport, index) => (
            <li key={index}>
              <button
                className="dropdown-item"
                onClick={() => toggleMainModal(quoteReport.label)}
              >
                {quoteReport.label}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <li>
        <button
          className="dropdown-item disabled-action"
          onClick={disableOnClickAction}
        >
          Invoice Reports
        </button>
        <ul className="dropdown-menu dropdown-submenu">
          {INVOICE_REPORTS.map((invoiceReport, index) => (
            <li key={index}>
              <button
                className="dropdown-item"
                onClick={() => toggleMainModal(invoiceReport.label)}
              >
                {invoiceReport.label}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <li>
        <button
          className="dropdown-item disabled-action"
          onClick={disableOnClickAction}
        >
          Income Reports
        </button>
        <ul className="dropdown-menu dropdown-submenu">
          {INCOME_REPORTS.map((incomeReport, index) => (
            <li key={index}>
              <button
                className="dropdown-item"
                onClick={() => toggleMainModal(incomeReport.label)}
              >
                {incomeReport.label}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <li>
        <button
          className="dropdown-item disabled-action"
          onClick={disableOnClickAction}
        >
          A/R Reports
        </button>
        <ul className="dropdown-menu dropdown-submenu">
          {ACCOUNTS_RECEIVABLE_REPORTS.map(
            (accountsReceivableReport, index) => (
              <li key={index}>
                <button
                  className="dropdown-item"
                  onClick={() =>
                    toggleMainModal(accountsReceivableReport.label)
                  }
                >
                  {accountsReceivableReport.label}
                </button>
              </li>
            )
          )}
        </ul>
      </li>
      <li>
        <button
          className="dropdown-item disabled-action"
          onClick={disableOnClickAction}
        >
          A/P Reports
        </button>
        <ul className="dropdown-menu dropdown-submenu">
          {ACCOUNTS_PAYABLE_REPORTS.map((accountsPayableReport, index) => (
            <li key={index}>
              <button
                className="dropdown-item"
                onClick={() => toggleMainModal(accountsPayableReport.label)}
              >
                {accountsPayableReport.label}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <li>
        <button
          className="dropdown-item disabled-action"
          onClick={disableOnClickAction}
        >
          Payroll Reports
        </button>
        <ul className="dropdown-menu dropdown-submenu">
          {PAYROLL_REPORTS.map((payrollReport, index) => (
            <li key={index}>
              <button
                className="dropdown-item"
                onClick={() => {
                  togglePayrollModal(payrollReport.label);
                }}
              >
                {payrollReport.label}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <ReportsModal
        modal={mainModal}
        toggle={toggleMainModal}
        printReportList={printReportList}
        dateRange={dateRange}
        register={register}
        handleSubmit={handleSubmit}
        Controller={Controller}
        control={control}
        setValue={setValue}
        reportLabel={reportLabel}
        showUserField={showUserField}
        setShowUserField={setShowUserField}
      ></ReportsModal>
      <EmployeePaystubReport
        modal={employeePaystubModal}
        toggle={toggleEmployeePaystubModal}
        printReportList={printReportList}
        dateRange={dateRange}
        register={register}
        handleSubmit={handleSubmit}
        Controller={Controller}
        control={control}
        setValue={setValue}
        reportLabel={reportLabel}
        showUserField={showUserField}
        setShowUserField={setShowUserField}
      ></EmployeePaystubReport>
      <T4Report
        modal={T4ReportModal}
        toggle={toggleT4ReportModal}
        year={year}
        register={register}
        handleSubmit={handleSubmit}
        Controller={Controller}
        control={control}
        setValue={setValue}
        reportLabel={reportLabel}
        showUserField={showUserField}
      ></T4Report>
      <ROEReport
        modal={ROEReportModal}
        toggle={toggleROEReportModal}
        year={year}
        register={register}
        handleSubmit={handleSubmit}
        Controller={Controller}
        control={control}
        setValue={setValue}
        reportLabel={reportLabel}
        showUserField={showUserField}
      ></ROEReport>
    </>
  );
}

export default ReportsMenu;
