import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { addCustomer, updateCustomer } from '../../services/customerServices';
import { showToast } from '../../utils/toastUtils';
import { isABMUser, isAccountAdmin, isSuperAdmin } from '../../utils/roleUtils';
import ModalRender from '../Commons/ModalRender';
import { PatternFormat } from 'react-number-format';
import { removePhoneFormat } from '../../utils/formatUtils';
import { useDispatch, useSelector } from 'react-redux';
import { PROVINCE, TAX_TYPES, CUSTOMER_TYPES } from '../../constants';
import { addCustomerData, setCustomerData } from '../../redux/customer/customerReducer';
import { getCustomerTypes } from '../../utils/customerUtils';
import { handlePostalCodeChange, formatPostalCode } from '../../utils/formatUtils';

function CustomerForm({
  isFormEdit = false,
  selectedCustomer = {},
  isFormHidden,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton
}) {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue, setError, clearErrors, reset, setFocus, control, formState: { errors, isDirty } } = useForm();
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const [province, setProvince] = useState("");
  const [defaultTax, setDefaultTax] = useState("");
  const [customerType, setCustomerType] = useState("");

  useEffect(() => {
    if (isFormEdit) {
      setProvince(parseInt(selectedCustomer['province']));
      setDefaultTax(parseInt(selectedCustomer['defaultTaxCode']));
      setCustomerType(parseInt(selectedCustomer['customerType']));
      setValue("province", parseInt(selectedCustomer['province']));
      setValue("defaultTaxCode", parseInt(selectedCustomer['defaultTaxCode']));
      setValue("customerType", parseInt(selectedCustomer['customerType']));
      setValue("active", selectedCustomer['active']);
      setValue("phone", selectedCustomer['phone']);
      setValue("fax", selectedCustomer['fax']);
      setValue("cell", selectedCustomer['cell']);
    } else {
      setProvince("");
      setDefaultTax("");
      setCustomerType("");
      reset()
    }

  }, [selectedCustomer["customerId"]])

  // // form values
  // setValue('customerId', 0);
  // setValue('accountId', accountId);

  // useEffects
  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("customerName");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    setIsFormDirty(isDirty)
  }, [isDirty])

  const getActiveDefaultValue = () => {
    if (selectedCustomer.length <= 0) {
      return 'true';
    }

    return selectedCustomer.active ? 'true' : '';
  }

  const onSubmit = async (payload) => {

    _.forOwn(payload, (value, key) => {
      if (typeof value === 'string') {
        payload[key] = value.trim();
      }
    });

    if (payload.postalCode) {
      payload.postalCode = payload.postalCode.replace(/\s+/g, ''); // Remove all spaces
    }

    let response = null;

    _.set(payload, 'phone', removePhoneFormat(payload.phone));
    _.set(payload, 'fax', removePhoneFormat(payload.fax));
    _.set(payload, 'cell', removePhoneFormat(payload.cell));

    if (payload.phone === "") {
      setError("phone", { message: "Phone is required" });
      return;
    }

    if (isFormEdit) {
      _.set(payload, 'customerId', selectedCustomer.customerId);
      _.set(payload, 'accountId', selectedCustomer.accountId);
      response = await updateCustomer(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully updated' : 'Failed to update'} customer.`
      });
    } else {
      _.set(payload, 'customerId', 0);
      if (isABMUser(userType)) {
        _.set(payload, 'accountId', accountState.accountId != null ? accountState.accountId : accountId);
      } else {
        _.set(payload, 'accountId', accountId);
      }
      response = await addCustomer(payload);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully added' : 'Failed to add'} customer.`
      });
    }

    if (response.ok) {
      const customerData = await response.json();
      dispatch(isFormEdit ? setCustomerData(customerData) : addCustomerData(customerData));

      toggleFormDisplay();

      if (isDirty) {
        setModal(false)
      }
      setIsFormDirty(false)
      reset()
      focusOnOpenOrCloseButton()
    }
  }

  return (
    <>
      <div className='content-section-container'>
        <div className='d-flex flex-row align-items-center mb-5'>
          <div className='form-title me-auto'>
            {`${isFormEdit ? 'Update' : 'Add'} Customer`}
          </div>
          <button className="btn btn-primary" form='customer-form' type="submit" tabIndex={16}>Save</button>
        </div>
        <form className="row form-container" id="customer-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="name" className="form-label">Name</label>
            <input type="text" className="form-control" id="name" tabIndex={1}
              defaultValue={isFormEdit ? selectedCustomer?.customerName || '' : ''}
              autoComplete='on'
              {...register("customerName", { required: "Name is required" })}
            />
            <small className='form-error-message'>
              {errors?.customerName && errors.customerName.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" tabIndex={2}
              defaultValue={isFormEdit ? selectedCustomer?.email || '' : ''}
              autoComplete='on'
              {...register("email", { required: "Email is required" })}
            />
            <small className='form-error-message'>
              {errors?.email && errors.email.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="defaultTaxCode" className="form-label" >Default Tax Code:</label>
            <select className="form-select" value={defaultTax} tabIndex={3} id="defaultTaxCode"{...register("defaultTaxCode", { valueAsNumber: true, onChange: (e) => setDefaultTax(e.target.value), required: "Default Tax Code is required" })}>
              <option key="none" value="" hidden>Default Tax Code</option>
              {TAX_TYPES.map((tax, index) =>
                <option key={index} value={tax.value}>{tax.label}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.defaultTaxCode && errors.defaultTaxCode.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="taxId" className="form-label">Tax ID:</label>
            <input type="text" className="form-control" id="taxId" tabIndex={4}
              defaultValue={isFormEdit ? selectedCustomer?.taxId || '' : ''}
              {...register("taxId", { required: "Tax ID is required" })}
            />
            <small className='form-error-message'>
              {errors?.taxId && errors.taxId.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="phone" className="form-label">Phone</label>
            <Controller
              control={control}
              name='phone'
              register={register}
              rules={{ required: "Phone is required" }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <PatternFormat value={value === undefined ? '' : value} onChange={onChange} onBlur={onBlur} getInputRef={ref} className='form-control' tabIndex={5} format="(###) ###-####" id="phone" mask="_" allowEmptyFormatting autoComplete='on' />
              )}
            />
            <small className='form-error-message'>
              {errors?.phone && errors.phone.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="fax" className="form-label">Fax</label>
            <Controller
              control={control}
              name='fax'
              register={register}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <PatternFormat defaultValue={isFormEdit ? selectedCustomer?.fax || '' : ''} value={isFormEdit ? selectedCustomer?.fax || '' : ''} onChange={onChange} tabIndex={6} onBlur={onBlur} className='form-control' format="(###) ###-####" id="fax" mask="_" allowEmptyFormatting />
              )}
            />
            <small className='form-error-message'>
              {errors?.fax && errors.fax.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="mobile" className="form-label">Mobile</label>
            <Controller
              control={control}
              name='cell'
              register={register}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <PatternFormat defaultValue={isFormEdit ? selectedCustomer?.cell || '' : ''} value={isFormEdit ? selectedCustomer?.cell || '' : ''} onChange={onChange} tabIndex={7} onBlur={onBlur} className='form-control' format="(###) ###-####" id="mobile" mask="_" allowEmptyFormatting />
              )}
            />
            <small className='form-error-message'>
              {errors?.cell && errors.cell.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="customerType" className="form-label" >Type:</label>
            <select className="form-select" value={customerType} tabIndex={8} id="customerType"{...register("customerType", { valueAsNumber: true, onChange: (e) => setCustomerType(e.target.value), required: "Customer Type is required" })}>
              <option key="none" value="" hidden>Customer Type</option>
              {getCustomerTypes(customerType).map((customerType, index) =>
                <option key={index} value={customerType.value}>{customerType.label}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.customerType && errors.customerType.message}
            </small>
          </div>
          <div className="col-12 my-2">
            <hr></hr>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="address1" className="form-label">Address Line 1</label>
            <input type="text" className="form-control" id="address1" tabIndex={9}
              defaultValue={isFormEdit ? selectedCustomer?.addressLine1 || '' : ''}
              {...register("addressLine1", { required: "Address Line 1 is required" })}
            />
            <small className='form-error-message'>
              {errors?.addressLine1 && errors.addressLine1.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="address2" className="form-label">Address Line 2</label>
            <input type="text" className="form-control" id="address2" tabIndex={10}
              defaultValue={isFormEdit ? selectedCustomer?.addressLine2 || '' : ''}
              {...register("addressLine2")}
            />
            <small className='form-error-message'>
              {errors?.addressLine2 && errors.addressLine2.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="city" className="form-label">City</label>
            <input type="text" className="form-control" id="city" tabIndex={11}
              defaultValue={isFormEdit ? selectedCustomer?.city || '' : ''}
              {...register("city", { required: "City is required" })}
            />
            <small className='form-error-message'>
              {errors?.city && errors.city.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="province" className="form-label">Province:</label>
            <select className="form-select" id="province" tabIndex={12} value={province} {...register("province", { valueAsNumber: true, onChange: (e) => setProvince(e.target.value), required: "Province is required" })}>
              <option key="none" value="" hidden>Province</option>
              {PROVINCE.map((prov, index) =>
                <option key={index} value={prov.value}>{prov.label}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.province && errors.province.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="postalCode" className="form-label">Postal Code</label>
            <input
              type="text"
              className="form-control"
              id="postalCode"
              tabIndex={13}
              defaultValue={isFormEdit ? formatPostalCode(selectedCustomer?.postalCode || '') : ''}
              {...register("postalCode", { required: "Postal Code is required", maxLength: 7 })}
              onChange={handlePostalCodeChange}
            />
            <small className="form-error-message">
              {errors?.postalCode && errors.postalCode.message}
            </small>
          </div>
          <div className="col-12 mb-2">
            <label htmlFor="notes" className="form-label">Notes</label>
            <textarea
              className="form-control"
              id="notes"
              tabIndex={14}
              rows={5}
              defaultValue={isFormEdit ? selectedCustomer?.notes || '' : ''}
              {...register("notes")}
            ></textarea>
            <small className='form-error-message'>
              {errors?.notes && errors.notes.message}
            </small>
          </div>
          {
            (isSuperAdmin(userType) || isAccountAdmin(userType)) &&
            <div className="col-12 mb-2 mt-3">
              <div className="form-check">
                <input className="form-check-input align-middle" type="checkbox" value="" id="active" tabIndex={15}
                  defaultChecked={getActiveDefaultValue}
                  {...register("active")}
                />
                <label className="form-check-label align-middle " htmlFor="active"> Active</label>
                <small className='form-error-message'>
                  {errors?.active && errors.active.message}
                </small>
              </div>
            </div>
          }
        </form>
        <ModalRender modal={modal} handleSubmit={handleSubmit} onSubmit={onSubmit} setModal={setModal} toggle={toggle} isFormEdit={isFormEdit} formType={"customer"} toggleFormDisplay={toggleFormDisplay} />
      </div>
    </>
  )
}

export default CustomerForm
