import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react';
import ContentHeader from '../Commons/Layouts/ContentHeader';
import EditFormRenderer from '../Templates/CellRenderers/Commons/EditFormRenderer';
import _, { initial } from 'lodash';
import { fetchExpensesFiltered, fetchExpensesFilteredWithBalances, addExpense, updateExpense } from '../../services/expenseServices';
import { currencyRendererSelector, updatedRowCheck } from '../../utils/quoteUtils';
import QuoteTaxableRenderer from '../Templates/CellRenderers/QuoteTaxableRenderer';
import ExpenseForm from './ExpenseForm';
import { fetchAccountById } from '../../services/accountServices';
import { fetchReporTemplateByAccount } from '../../services/reportTemplateServices';
import parse from 'html-react-parser';
import { formatTaxRate, enumValueFormatter, formatDate } from '../../utils/formatUtils';
import { useSelector } from 'react-redux';
import { isABMUser } from '../../utils/roleUtils';
import { getReportValue } from '../../utils/reportTemplateUtils';
import { createExpensesReportFile } from '../../services/reportServices';
import { printPreviewReportWithBase64 } from '../../utils/payrollRunUtils';
import { showToast } from '../../utils/toastUtils';
import { processCSVExportCellCallback } from '../../utils/csvUtils';
import moment from 'moment';
import { PAYMENT_TYPES } from '../../constants';
import QuoteTaxableEditor from '../Templates/Editor/QuoteTaxableEditor';
import { NumericCellEditor } from '../Commons/Editor/NumericCellEditor';
import ModeOfPaymentEditor from '../Templates/Editor/ModeOfPaymentEditor';
import CompanyEditor from '../Templates/Editor/CompanyEditor';
import ExpenseDateEditor from '../Templates/Editor/ExpenseDateEditor';
import { fetchGLCodesFiltered, } from "../../services/glCodeServices";
import GLCodeEditor from '../Templates/Editor/GLCodeEditor';
import { formatDateOnly } from '../../utils/formatUtils';
import { ConfirmationModal } from '../Commons/Modals/ConfirmationModal';
import { calculateQuote } from '../../utils/quoteUtils';
import { suppressKeyboardEvent } from '../../utils/gridUtils';
import { expenseEditRules, expenseEditClass } from '../../utils/expenseUtils';
import { Tooltip as ReactTooltip } from "react-tooltip";

function ExpensesQuickEntry() {
  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector((state) => state.account.selectedAccount);
  const editButtonRef = useRef(null);
  const [gLCodeList, setGLCodeList] = useState([]);
  const [isPinnedRowVisible, setIsPinnedRowVisible] = useState(false);
  const [hasDirtyAddColumns, setHasDirtyAddColumns] = useState(false);
  const [hasDirtyEditColumns, setHasDirtyEditColumns] = useState(false);
  const [customerFilter, setCustomerFilter] = useState(true);
  const customerFilterRef = useRef(null);

  // useRefs
  const gridRef = useRef();
  const openOrCloseButtonRef = useRef(null);
  const currentProvince = useRef();
  const glCodeListRef = useRef(null);
  const [pinnedRowHasChangesModal, setPinnedRowHasChangesWarningModal] = useState(false);
  const pinnedRowHasChangesToggle = () => setPinnedRowHasChangesWarningModal(!pinnedRowHasChangesModal);

  const pinnedRowHasChangesOnClick = () => {
    if (hasDirtyAddColumns) {
      setHasDirtyAddColumns(!hasDirtyAddColumns);
      setIsPinnedRowVisible(!isPinnedRowVisible);
    }
    setPinnedRowHasChangesWarningModal(!pinnedRowHasChangesModal)
  };

  const toggleFormDisplay = (isEdit = false, expenseId = null) => {
    if (isFormHidden) {
      setSelectedExpenseId(expenseId);
    } else {
      setSelectedExpenseId(null)
    }
    if (modal) {
      setModal(!modal)
    }

    setIsFormEdit(isEdit);
    setIsFormHidden(!isFormHidden);
    setTimeout(() => {
      gridRef.current.api.sizeColumnsToFit();
    }, 50);
  }

  const columnDefsWithRow = [
    {
      field: '#',
      width: 50,
      minWidth: 50,
      suppressSizeToFit: true,
      cellClass: 'no-borders',
      cellStyle: { fontWeight: 'bold' }
    },
    {
      field: 'Description',
      minWidth: 120,
    },
    {
      field: 'Company',
      minWidth: 100
    },
    {
      field: 'QTY',
      minWidth: 110,
    },
    {
      field: 'Total',
      minWidth: 110,
      cellRendererSelector: currencyRendererSelector,
    },
  ];

  const togglePinnedRow = () => {
    if (gridRef.current) {
      const api = gridRef.current.api;

      if (!isPinnedRowVisible) {
        setInputRow(initialInputRow)
        focusOnFirstPinnedTopRow();
        setIsPinnedRowVisible(!isPinnedRowVisible);
      } else {
        if (hasDirtyAddColumns) {
          pinnedRowHasChangesToggle();
        } else {
          setIsPinnedRowVisible(!isPinnedRowVisible);
        }
      }
    }
  };

  const submitData = async (node, api) => {
    var currentAccountId = accountId;
    if (isABMUser(userType) && selectedAccountState.accountId !== null) {
      currentAccountId = selectedAccountState.accountId;
    }

    if (node?.rowPinned === 'top') {
      await newExpense(node, currentAccountId);
    } else {
      await updateExpenses(node, api, currentAccountId);
    }
  }

  const newExpense = async (node, currentAccountId) => {
    const data = node?.data;
    let response = null;

    var currentDate = moment().clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    var expenseDate = moment(data?.['Expense Date']).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');

    const payload = {
      accountId: currentAccountId,
      balance: data?.['Balance'],
      company: data?.['Company'],
      companyName: data?.['Company'],
      currentDate: currentDate,
      customerId: data?.['Customer Id'],
      description: data?.['Description'],
      discount: data?.['Discount'],
      expenseDate: expenseDate,
      expenseId: data?.['Expense Id'],
      expenseTemplate: null,
      glCodeId: data?.['GL Code'],
      itemId: data?.['Item Id'],
      itemType: data?.['Item Type'],
      paymentType: data?.['Mode of Payment'],
      quantity: data?.['QTY'],
      rate: data?.['Unit Price'],
      referenceId: data?.['Reference Id'],
      subtotal: data?.['Subtotal'],
      taxRate: data?.['Tax Rate'],
      taxType: data?.['Tax Type'],
      total: data?.['Total'],
      totalTax: data?.['Total Tax'],
    }

    response = await addExpense(payload);
    showToast({
      type: `${response.ok ? "success" : "error"}`,
      message: `${response.ok ? "Successfully added" : "Failed to add"
        } expense.`,
    });

    if (response.ok) {
      setIsPinnedRowVisible(false);
      setHasDirtyAddColumns(false);
      setHasDirtyEditColumns(false);
      await fillExpenseList();
      openOrCloseButtonRef.current.focus();
    }
  }

  const updateExpenses = async (node, api, currentAccountId) => {
    let response = null;
    const editedRows = [];
    let submitSucceeded = true;
    api.forEachNode((item) => {
      if (item.data?.['FormUpdated']) {
        editedRows.push(item.data)
      }
    });

    for (const data of editedRows) {
      var currentDate = moment().clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
      var expenseDate = moment(data?.['Expense Date']).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');

      const payload = {
        accountId: currentAccountId,
        balance: data?.['Balance'],
        company: data?.['Company'],
        companyName: data?.['Company'],
        currentDate: currentDate,
        customerId: data?.['Customer Id'],
        description: data?.['Description'],
        discount: data?.['Discount'],
        expenseDate: expenseDate,
        expenseId: data?.['Expense Id'],
        expenseTemplate: null,
        glCodeId: data?.['GL Code'],
        itemId: data?.['Item Id'],
        itemType: data?.['Item Type'],
        paymentType: data?.['Mode of Payment'],
        quantity: data?.['QTY'],
        rate: data?.['Unit Price'],
        referenceId: data?.['Reference Id'],
        subtotal: data?.['Subtotal'],
        taxRate: data?.['Tax Rate'],
        taxType: data?.['Tax Type'],
        total: data?.['Total'],
        totalTax: data?.['Total Tax'],
      }
      try {
        response = await updateExpense(payload);
      } catch (error) {
        submitSucceeded = false;
        showToast({
          type: "error",
          message: `Updating expenese(s) failed. Please try again.`,
        });
        return
      }
    }

    if (submitSucceeded) {
      showToast({
        type: `success`,
        message: `Succesfully updated expense(s).`,
      });
      setIsPinnedRowVisible(false);
      setHasDirtyAddColumns(false);
      setHasDirtyEditColumns(false);

      await fillExpenseList();
    }
  }

  const columnDefinitions = (isPrintableLayout = false) => {
    const defs = [
      {
        field: '#',
        width: 50,
        minWidth: 50,
        cellClass: params => `grid-column ${params.node.rowPinned === 'top' ? 'top-row' : ''} ${params.node.rowPinned !== 'top' && params.data?.['FormCompleted'] && hasDirtyEditColumns ? 'dirty-row' : ''}`,
        suppressSizeToFit: true,
        cellStyle: { fontWeight: 'bold' },
        maxWidth: isPrintableLayout ? 50 : null
      },
      {
        field: 'Expense Date',
        minWidth: 118,
        maxWidth: isPrintableLayout ? 120 : null,
        cellClass: params => expenseEditClass(params,),
        editable: params => {
          return expenseEditRules(params);
        },
        cellEditor: ExpenseDateEditor,
        valueFormatter: (params) => {
          return formatDateOnly(params.value);
        },
        valueSetter: (params) => {
          updatedRowCheck(params, isPinnedRowDataCompleted)
          return true;
        },
      },
      {
        field: 'Description',
        minWidth: 120,
        maxWidth: isPrintableLayout ? 120 : null,
        cellClass: params => expenseEditClass(params,),
        editable: params => {
          return expenseEditRules(params);
        },
        valueSetter: (params) => {
          updatedRowCheck(params, isPinnedRowDataCompleted)
          return true;
        }
      },
      {
        field: 'Company',
        minWidth: 200,
        maxWidth: isPrintableLayout ? 200 : null,
        cellClass: params => expenseEditClass(params,),
        editable: params => {
          return expenseEditRules(params);
        },
        valueFormatter: (params) => {
          return params.value || 'Select Company...'
        },
        valueSetter: (params) => {
          updatedRowCheck(params, isPinnedRowDataCompleted)
          return true;
        },
        cellEditor: CompanyEditor,
        cellEditorParams: {
          customerFilter: () => customerFilterRef.current
          // customerFilter: () => customerFilter
        }
      },
      {
        field: 'Mode of Payment',
        minWidth: 120,
        maxWidth: isPrintableLayout ? 120 : null,
        cellClass: params => expenseEditClass(params,),
        cellEditor: ModeOfPaymentEditor,
        cellEditorParams: {
          gridRef: gridRef,
        },
        valueFormatter: (params) => {
          return enumValueFormatter(params, PAYMENT_TYPES) || 'None';
        },
        valueSetter: (params) => {
          updatedRowCheck(params, isPinnedRowDataCompleted)
          return true;
        },
        editable: params => {
          return expenseEditRules(params);
        },
      },
      {
        field: 'GL Code',
        minWidth: 150,
        cellClass: params => expenseEditClass(params,),
        valueFormatter: (params) => {
          return params.data['GL Code Name'] || 'None';
        },
        maxWidth: isPrintableLayout ? 120 : null,
        editable: params => {
          return expenseEditRules(params);
        },
        valueSetter: (params) => {
          updatedRowCheck(params, isPinnedRowDataCompleted)
          return true;
        },
        cellEditor: GLCodeEditor,
        cellEditorPopup: true,
        cellEditorParams: (params) => ({
          glCodeList: glCodeListRef, // Ensure latest state is captured
        }),
      },
      {
        field: 'Reference Id',
        minWidth: 120,
        maxWidth: isPrintableLayout ? 120 : null,
        cellClass: params => expenseEditClass(params,),
        editable: params => {
          return expenseEditRules(params);
        },
        valueSetter: (params) => {
          updatedRowCheck(params, isPinnedRowDataCompleted)
          return true;
        }
      },
      {
        field: 'QTY',
        minWidth: 100,
        maxWidth: isPrintableLayout ? 110 : null,
        cellClass: params => expenseEditClass(params,),
        cellEditor: NumericCellEditor,
        editable: params => {
          return expenseEditRules(params);
        },
        valueSetter: (params) => {
          calculateQuote(params, currentProvince.current, isPinnedRowDataCompleted)
          return true;
        }
      },
      {
        field: 'Unit Price',
        minWidth: 100,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null,
        cellClass: params => expenseEditClass(params,),
        cellEditor: NumericCellEditor,
        editable: params => {
          return expenseEditRules(params);
        },
        valueSetter: (params) => {
          calculateQuote(params, currentProvince.current, isPinnedRowDataCompleted)
          return true;
        }
      },
      {
        field: 'Tax Type',
        minWidth: 110,
        cellClass: params => expenseEditClass(params,),
        headerName: 'Tax Type',
        valueSetter: (params) => {
          calculateQuote(params, currentProvince.current, isPinnedRowDataCompleted)
          return true;
        },
        cellRendererSelector: (params) => {
          if (params.data['Tax Type'] !== undefined) {
            return {
              component: QuoteTaxableRenderer
            }
          } else {
            return undefined;
          }
        },
        cellEditor: QuoteTaxableEditor,
        cellEditorParams: {
          province: () => currentProvince.current,
          gridRef: gridRef,
        },
        autoHeight: true,
        maxWidth: isPrintableLayout ? 100 : null,
        cellClass: params => expenseEditClass(params,),
        editable: params => {
          return expenseEditRules(params);
        },
      },
      {
        field: 'Tax Rate',
        headerName: 'Tax Rate',
        valueGetter: params => formatTaxRate(params.data["Tax Rate"]),
        valueFormatter: params => formatTaxRate(params.data["Tax Rate"]) + '%',
        autoHeight: true,
        minWidth: 100,
        maxWidth: isPrintableLayout ? 100 : null,
        cellClass: params => expenseEditClass(params,),
        editable: (params) => {
          return expenseEditRules(params);
        },
        valueSetter: (params) => {
          calculateQuote(params, currentProvince.current, isPinnedRowDataCompleted)
          return true;
        },
      },
      {
        field: 'Discount',
        minWidth: 100,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 100 : null,
        cellClass: params => expenseEditClass(params,),
        cellEditor: NumericCellEditor,
        editable: params => {
          return expenseEditRules(params);
        },
        valueSetter: (params) => {
          calculateQuote(params, currentProvince.current, isPinnedRowDataCompleted)
          return true;
        },
      },
      {
        field: 'Subtotal',
        minWidth: 100,
        cellClass: params => expenseEditClass(params,),
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 100 : null,
      },
      {
        field: 'Total',
        minWidth: 100,
        cellClass: params => expenseEditClass(params,),
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 100 : null
      },
      {
        field: 'Total Tax',
        minWidth: 100,
        cellClass: params => expenseEditClass(params,),
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 100 : null
      },
      {
        field: 'Balance',
        minWidth: 100,
        cellClass: params => expenseEditClass(params,),
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 100 : null
      },
      {
        field: 'Edit',
        minWidth: 100,
        headerName: '',
        cellClass: params => `d-flex flex-row-reverse non-printable grid-column ${params.node.rowPinned === 'top' ? 'top-row' : ''}`,
        autoHeight: true,
        cellEditor: null,
        cellEditorSelector: null,
        cellRenderer: EditFormRenderer,
        cellRendererParams: {
          toggleFormDisplay: toggleFormDisplay,
          easyEdit: true,
          submitData: submitData,
          editButtonRef: editButtonRef
        },
      },
    ];

    return defs;
  }

  // useStates
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(columnDefinitions());
  const [isFormHidden, setIsFormHidden] = useState(true);
  const [expenseList, setExpenseList] = useState([]);
  const [isFormEdit, setIsFormEdit] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState({});
  const [selectedExpenseId, setSelectedExpenseId] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(null)
  const [modal, setModal] = useState(false);
  const [accountProvince, setAccountProvince] = useState("");
  const [reportTemplate, setReportTemplate] = useState({});
  const [printMode, setPrintMode] = useState(false);
  const [balanceFilter, setBalanceFilter] = useState(true);

  const toggle = () => setModal(!modal);

  const initialInputRow = {
    'Expense Id': 0,
    'Expense Date': new Date(),
    Description: null,
    Company: null,
    'Mode of Payment': null,
    'GL Code': null,
    'Reference Id': null,
    QTY: 0,
    'Unit Price': 0,
    'Tax Type': 0,
    'Tax Rate': 0,
    Discount: 0,
    Subtotal: 0,
    'Total Tax': 0,
    Total: 0,
    Balance: 0,
    'Item Id': 0,
    'Item Type': 0,
    'Payments': 0,
    FormCompleted: false,
    FormUpdated: false,
  };

  const [inputRow, setInputRow] = useState(initialInputRow);

  // useEffects
  useEffect(() => {
    customerFilterRef.current = true
    focusOnOpenOrCloseButton();
    fillAccountProvince();
    fillGLCode();
  }, [])

  useEffect(() => {
    fillExpenseList();
  }, [balanceFilter])

  useEffect(() => {
    fillReportTemplate()
  }, [accountId])

  useEffect(() => {
    setColumnDefs(isFormHidden ? columnDefinitions(false) : columnDefsWithRow);
  }, [isFormHidden]);

  useEffect(() => {
    let data = [];
    _.each(expenseList, (expense, index) => {
      const glCodeName = expense.glCode?.glCodeName ? expense?.glCode?.glCodeName + ' - ' + expense?.glCode?.description : 'None';
      data.push({
        '#': index + 1,
        'Expense Id': expense.expenseId,
        'Account Id': expense.accountId,
        'Customer Id': expense.customerId,
        'Expense Date': moment(expense.expenseDate).hour(7).minute(0).second(0).toDate(),
        'Description': expense.description,
        'Company': expense.companyName,
        'Mode of Payment': expense.paymentType,
        'GL Code': expense.glCodeId,
        'GL Code Name': glCodeName,
        'Reference Id': expense.referenceId,
        QTY: expense.quantity,
        'Unit Price': parseFloat(expense.rate),
        'Tax Type': expense.taxType,
        'Tax Rate': expense.taxRate,
        Discount: parseFloat(expense.discount),
        Subtotal: parseFloat(expense.subtotal),
        "Total Tax": parseFloat(expense.totalTax),
        Total: parseFloat(expense.total),
        "Balance": parseFloat(expense.balance),
        'Payments': expense.payment,
        'Item Id': expense.itemId,
        'Item Type': expense.itemType,
        'Edit': { id: expense.expenseId },
        FormUpdated: 0,
        'FormCompleted': 1,
      });
    });

    setRowData(data);
  }, [expenseList])

  useEffect(() => {
    fillSelectedExpense(selectedExpenseId);
  }, [selectedExpenseId]);

  const fillGLCode = async (id) => {
    const accountTypeId = parseInt(
      process.env.REACT_APP_ACCOUNT_TYPE_COST_OF_GOODS_SOLD
    );
    const response = await fetchGLCodesFiltered();

    const filteredGLCodes = response.filter(
      (gl) => gl.glCodeName >= 5000 && gl.glCodeName < 6000
    );

    const glCodes = filteredGLCodes?.map((glCode, index) => ({
      value: glCode.glCodeId,
      label: glCode.glCodeName + " - " + glCode.description,
    }));
    glCodes.unshift({ value: null, label: "None" });
    setGLCodeList(glCodes);
    glCodeListRef.current = glCodes;
  };

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        textAlign: 'left',
      },
      suppressKeyboardEvent,
      resizable: true,
      valueFormatter: (params) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : undefined
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const fillExpenseList = async () => {
    let response = null;

    if (isABMUser(userType) && selectedAccountState.accountId !== null) {
      response = await fetchExpensesFiltered(balanceFilter);
    } else {
      response = await fetchExpensesFiltered(balanceFilter);
    }
    setExpenseList(await response);
  }

  //Update after user identity
  const fillAccountProvince = async () => {
    let response = null;
    response = await fetchAccountById(accountId);

    setAccountProvince(parseInt(response['province']))
    currentProvince.current = parseInt(response['province']);
  }

  const fillSelectedExpense = (id) => {
    const expense = _.find(expenseList, { expenseId: id });
    setSelectedExpense(expense);
  }

  const fillReportTemplate = async () => {
    const response = await fetchReporTemplateByAccount(userType, selectedAccountState.accountId, accountId, getReportValue("Expense").value).catch(e => {
      setReportTemplate({})
    })
    setReportTemplate(response);
  }

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  }

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  }

  const printExpenses = async () => {
    const payload = {
      accountId: selectedAccountState.accountId === null ? accountId : selectedAccountState.accountId,
      filter: balanceFilter ? "unpaid" : null
    };

    const response = await createExpensesReportFile(payload)
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      showToast({
        type: 'error',
        message: data.errorMessage
      });
      return;
    }

    printPreviewReportWithBase64(data.base64);
  }

  const headerCheck = () => {
    return printMode === true && reportTemplate?.header !== undefined && reportTemplate.useHeader === true
  }

  const footerCheck = () => {
    return printMode === true && reportTemplate?.footer !== undefined && reportTemplate.useFooter === true
  }

  const defaultSpacerCheck = () => {
    return printMode === true && reportTemplate?.footer !== undefined && reportTemplate.useDefaultSpacer === true;
  }

  const exportDataAsCSV = useCallback(() => {
    const params = {
      columnKeys: [
        'Description',
        'Company',
        'QTY',
        'Unit Price',
        'Tax Type',
        'Tax Rate',
        'Discount',
        'Subtotal',
        'Total Tax',
        'Total',
        'Balance'
      ],
      fileName: `expenses-${moment(new Date()).format("YYYY-MM-DD")}.csv`,
      processCellCallback: processCSVExportCellCallback,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  const focusOnOpenOrCloseButton = () => {
    openOrCloseButtonRef.current.focus();
  }

  const isEmptyPinnedCell = (params) => {
    return (
      (params.node.rowPinned === 'top' && params.value == null) ||
      (params.node.rowPinned === 'top' && params.value === '')
    );
  }

  const createPinnedCellPlaceholder = ({ colDef }) => {

    const formattedField = colDef.field[0].toUpperCase() + colDef.field.slice(1);
    return colDef.field === '#' ? formattedField : `${formattedField}...`;
  };

  const isPinnedRowDataCompleted = () => {
    const columnsToSkip = ['#', 'Discount', 'GL Code', 'Edit', 'Reference Id'];
    const pinnedTopRow = gridRef.current.api.getPinnedTopRow(0)?.data;
    if (!pinnedTopRow) return false;

    let allCompleted = true;
    columnDefs.forEach((def) => {
      if (columnsToSkip.includes(def.field)) {
        return;
      }

      const fieldValue = pinnedTopRow[def.field];

      if (fieldValue === undefined || fieldValue === null) {
        allCompleted = false;
      }

      else if (fieldValue === '') {
        allCompleted = false;
      }
    });

    return allCompleted;
  };

  const getRowStyle = useCallback(
    ({ node }) =>
      node.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : {},
    []
  );


  const focusOnFirstPinnedTopRow = () => {
    if (gridRef.current) {
      setTimeout(() => {
        const api = gridRef.current.api;
        const pinnedTopRowCount = api.getPinnedTopRowCount();

        if (pinnedTopRowCount > 0) {
          const firstPinnedRowIndex = 0; // Index for the first pinned row
          handleScrollLeft();
          api.ensureIndexVisible(firstPinnedRowIndex, 'top');

          setTimeout(() => {
            api.startEditingCell({
              rowIndex: firstPinnedRowIndex,
              rowPinned: 'top',
              colKey: 'Expense Date',
            });
          }, 100)

        }
      }, 100);
    }
  };

  const handleScrollLeft = () => {
    if (gridRef.current) {
      const firstColumn = gridRef.current.columnApi.getColumns()[0];
      gridRef.current.api.ensureColumnVisible(firstColumn);
    }
  };

  const handleTabToNextCell = useCallback((params) => {
    const gridApi = params.api;
    const previousCell = params.previousCellPosition;
    const nextCell = params.nextCellPosition;

    if (nextCell === null) {
      return
    }
    const data = nextCell.rowPinned ? params.api.getPinnedTopRow(nextCell.rowIndex).data : params.api.getDisplayedRowAtIndex(nextCell.rowIndex).data;

    if (previousCell.column.colId === "Discount" && params.editing && !params.backwards) {
      if (nextCell.rowPinned) {
        setTimeout(() => {
          gridApi.stopEditing();
          gridApi.setFocusedCell(nextCell.rowIndex, data?.["FormCompleted"] ? "Edit" : "Subtotal", nextCell.rowPinned);
        }, 0)
        return
      } else {
        setTimeout(() => {
          gridApi.stopEditing();
          gridApi.setFocusedCell(nextCell.rowIndex, data?.["FormUpdated"] ? "Edit" : "Subtotal", nextCell.rowPinned);
        }, 0)
        return
      }
    }

    const result = {
      rowIndex: nextCell.rowIndex,
      column: nextCell.column,
      rowPinned: nextCell.rowPinned,
    };

    setTimeout(() => {
      gridApi.ensureIndexVisible(nextCell.rowIndex);
      gridApi.ensureColumnVisible(nextCell.column);
      if (!params.editing) {
        gridApi.setFocusedCell(nextCell.rowIndex, nextCell.column.getId(), nextCell.rowPinned);
      }
    }, 0);

    return result;

  }, []);

  const handleCellFocused = (params) => {
    if (params.column.getColId() === 'Edit') {
      setTimeout(() => {
        document.getElementById(`save-button-${params.rowPinned ? 'top' : params.rowIndex}`)?.focus()
      }, 100)
    }
  }

  const handleCellValueChanged = (params) => {
    if (params.node.rowPinned === 'top') {
      setHasDirtyAddColumns(true);
    } else {
      setHasDirtyEditColumns(true);
    }
  }

  const applyCustomFilter = (e) => {
    setCustomerFilter(e.target.checked);
    customerFilterRef.current = e.target.checked;

    const params = {
      force: true,
      columns: ['Company'],
    };
    // gridRef.current.grid?.refreshCells(params);
  }

  return (
    <div>
      {
        headerCheck() && <div>
          {parse(reportTemplate?.header)}
        </div>
      }
      <div className='d-flex flex-row align-items-center content-header-container non-printable'>
        <div className='me-auto'>
          <ContentHeader
            title="Expenses"
            dataCount={expenseList.length}
            filterData={filterData}
            setBalanceFilter={setBalanceFilter}
            showBalanceFilter={true}
            balanceDisabledCheck={isPinnedRowVisible || hasDirtyEditColumns}
            applyCustomFilter={applyCustomFilter}
          />
        </div>
        <button className="btn btn-lg btn-primary me-2" onClick={exportDataAsCSV} disabled={!isFormHidden}>Export as CSV</button>
        <button className="btn btn-lg btn-primary me-2" onClick={printExpenses}>Print</button>
        <span id='pinned-row-button'>
          <button
            className={`btn btn-lg ${!isPinnedRowVisible ? 'btn-primary' : 'btn-secondary'}`}
            type="submit"
            onClick={togglePinnedRow}
            ref={openOrCloseButtonRef}
            disabled={hasDirtyEditColumns}

          >
            {!isPinnedRowVisible ? 'Add Expense' : 'Close'}
          </button>
        </span>
      </div>
      <div className="content-body-container row">
        <div className={`${isFormHidden ? 'col-12' : 'col-12 col-lg-5 min-vh-25'} mb-2`}>
          <div id="expenseGrid" className={`ag-theme-alpine content-section-container shadow-none`} >
            <AgGridReact
              className='no-header'
              rowData={rowData}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onColumnSizeChanged={onColumnsSizeChanged}
              onGridSizeChanged={onGridSizeChanged}
              rowHeight={70}
              rowSelection={'single'}
              pinnedTopRowData={isPinnedRowVisible ? [inputRow] : []}
              getRowStyle={getRowStyle}
              tabToNextCell={handleTabToNextCell}
              onCellFocused={handleCellFocused}
              onCellValueChanged={handleCellValueChanged}
              rowDataChangeDetectionStrategy={'IdentityCheck'}
            >
            </AgGridReact>
          </div>
        </div>
        <div className={`col-12 col-lg-7 ${isFormHidden ? 'd-none' : ''}`}>
          <ExpenseForm
            isFormEdit={isFormEdit}
            selectedExpense={selectedExpense}
            isFormHidden={isFormHidden}
            fillExpenseList={fillExpenseList}
            toggleFormDisplay={toggleFormDisplay}
            setIsFormDirty={setIsFormDirty}
            modal={modal}
            setModal={setModal}
            toggle={toggle}
            accountProvince={accountProvince}
            focusOnOpenOrCloseButton={focusOnOpenOrCloseButton}
          />
        </div>
      </div>

      {hasDirtyEditColumns &&
        (<ReactTooltip
          anchorId="pinned-row-button"
          className='tooltip-container'
          place="top"
          content="Expenses cannot be added during editing"
        />)
      }

      <ConfirmationModal
        isOpen={pinnedRowHasChangesModal}
        toggle={pinnedRowHasChangesToggle}
        onClick={pinnedRowHasChangesOnClick}
        headerText={"Create Expense"}
        bodyText={"Are you sure you want to cancel creating an expense?"}
        confirmButtonText="Ok"
        cancelButtonText="Cancel"
        buttonColor="danger"
        focusOnConfirm={true}
      />
      {
        defaultSpacerCheck() && (
          <>
            <br />
            <hr />
            <br />
          </>
        )
      }
      {
        footerCheck() && <div>
          {parse(reportTemplate?.footer)}
        </div>
      }
    </div>
  )
}

export default ExpensesQuickEntry
