import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import Select from "react-select";
import { selectStyle } from "../../../utils/styleUtils";
import { fetchUsersDropdown } from "../../../services/userServices";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { useSelector } from "react-redux";
import { createROEReportFile } from "../../../services/reportServices";

function ROEReport({
  modal,
  toggle,
  reportLabel,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [userOptions, setUserOptions] = useState([]);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const accountId = useSelector((state) => state.auth.user.accountId);

  // Dropdown options for "Reason"
  const reasonOptions = [
    { value: "A", label: "Code A - Shortage of work (Layoff)" },
    { value: "B", label: "Code B - Strike or Lockout" },
    { value: "C", label: "Code C - Return to school" },
    { value: "D", label: "Code D - Illness or Injury" },
    { value: "E", label: "Code E - Quit" },
    { value: "F", label: "Code F - Maternity" },
    { value: "G", label: "Code G - Retirement" },
    { value: "H", label: "Code H - Work-Sharing" },
    { value: "J", label: "Code J - Apprentice training" },
    { value: "M", label: "Code M – Dismissal or Suspension" },
    { value: "N", label: "Code N - Leave of absence" },
    { value: "P", label: "Code P - Parental" },
    { value: "Z", label: "Code Z - Compassionate care/Family caregiver" },
    { value: "K", label: "Code K - Other" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserList();
    };
    fetchData();
  }, []);

  const fetchUserList = async () => {
    try {
      const response = await fetchUsersDropdown();
      const users = response.map((user) => ({
        value: user.userId,
        label: `${user.firstName} ${user.lastName}`,
      }));
      
      setUserOptions(users);
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  const onSubmit = async (payload) => {
    const userId = payload.userId != null ? payload.userId.value : null;

    const reportPayload = {
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      roeYear: moment(`${payload.year}-01-01`).format("YYYY-MM-DD"),
      userId: userId,
      reason: payload.reason?.value,
    };

    try {
      const response = await createROEReportFile(reportPayload);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "EmployeeReport.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      toggle(false);
    } catch (error) {
      console.error("Error generating report:", error);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, [selectedAccountState]);

  return (
    <Modal isOpen={modal}>
      <ModalHeader toggle={() => toggle(null)}>
        {reportLabel !== null ? `${reportLabel} Report` : "ROE"}
      </ModalHeader>
      <ModalBody>
        <form
          className="row form-container justify-content-center align-items-center"
          id="create-report"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col-6 mb-3">
            <label htmlFor="userId" className="form-label">
              Employee
            </label>
            <div className="custom-selector" style={{ maxWidth: "230px" }}>
              <Controller
                name="userId"
                control={control}
                rules={{ required: "Please select an employee" }}
                render={({ field }) => (
                  <Select
                    onChange={field.onChange}
                    value={field.value}
                    options={userOptions}
                    className="expense-select mb-1"
                    placeholder={"Select an Employee"}
                    id="accountId"
                    styles={selectStyle}
                    isClearable
                    tabIndex={1}
                  />
                )}
              />
              <small className="form-error-message">
                {errors?.userId && errors.userId.message}
              </small>
            </div>
          </div>

          <div className="col-6 mb-3">
            <label htmlFor="reason" className="form-label">
              Reason
            </label>
            <div className="custom-selector" style={{ maxWidth: "230px" }}>
              <Controller
                name="reason"
                control={control}
                rules={{ required: "Please select a reason" }}
                render={({ field }) => (
                  <Select
                    onChange={field.onChange}
                    value={field.value}
                    options={reasonOptions} 
                    className="expense-select mb-1"
                    placeholder="Select a Reason"
                    id="reason"
                    styles={selectStyle}
                    isClearable
                    tabIndex={2}
                  />
                )}
              />
              <small className="form-error-message">
                {errors?.reason && errors.reason.message}
              </small>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-end mt-2">
            <button
              className="btn btn-primary me-2"
              type="button"
              onClick={toggle}
              style={{ width: "100px", padding: "8px 20px" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              form="create-report"
              type="submit"
              style={{ width: "130px", padding: "8px 20px" }}
            >
              Download CSV
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default ROEReport;
