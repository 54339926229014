import React from "react";

function PayMethodTypeRenderer(props) {
  const payMethodType = props.data["Pay Method Type"];
  var display = "pay method type...";
  switch (parseInt(payMethodType)) {
    case 0:
      display = "Salary";
      break;
    case 1:
      display = "Hours";
      break;
    case 2:
      display = "Miles";
      break;
    case 3:
      display = "Other";
      break;
    case 4:
      display = "Advance";
      break;
    case 5:
      display = "Reimbursement";
      break;
    default:
      display = "Not set";
      break;
  }

  return (
    <>
      <span>{display}</span>
    </>
  );
}

export default PayMethodTypeRenderer;
