import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInvoicesListData } from "../../redux/invoice/invoiceReducer";
import {
  fetchInvoices,
  fetchInvoicesFiltered,
} from "../../services/invoiceServices";
import { formatCurrency, formatDateOnly } from "../../utils/formatUtils";
import { getInvoiceBalance } from "../../utils/paymentUtils";
import { statusFilter, statusValueGetter } from "../../utils/quoteUtils";
import { isABMUser, isSuperAdmin } from "../../utils/roleUtils";
import ContentHeader from "../Commons/Layouts/ContentHeader";
import ProfilePictureRenderer from "../Templates/CellRenderers/Commons/ProfilePictureRenderer";
import ActionRenderer from "../Templates/CellRenderers/Invoices/ActionRenderer";
import StatusRenderer from "../Templates/CellRenderers/StatusRenderer";

function Invoices() {
  const dispatch = useDispatch();
  const gridRef = useRef();

  const accountId = useSelector((state) => state.auth.user.accountId);
  const userType = useSelector((state) => state.auth.user.userType);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const invoiceState = useSelector((state) => state.invoice);

  const [rowData, setRowData] = useState([]);
  const [invoicesList, setInvoicesList] = useState([]);

  const [columnDefs] = useState([
    {
      field: "index",
      headerName: "#",
      width: 50,
      suppressSizeToFit: true,
      cellStyle: { fontWeight: "bold" },
    },
    {
      field: "ProfilePicture",
      headerName: "",
      minWidth: 70,
      cellRenderer: ProfilePictureRenderer,
      width: 70,
      suppressSizeToFit: true,
      cellClass: "no-borders",
    },
    {
      field: "Customer",
      autoHeight: true,
      cellClass: "no-borders",
      minWidth: 120,
      width: 120,
      filter: "agTextColumnFilter",
      sortable: true,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Invoice Id",
      minWidth: 110,
      cellClass: "no-borders",
      width: 120,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Quote Name",
      minWidth: 120,
      autoHeight: true,
      cellClass: "no-borders",
    },
    {
      field: "Status",
      cellRenderer: StatusRenderer,
      autoHeight: true,
      cellClass: "no-borders",
      minWidth: 120,
      width: 120,
      filter: "agTextColumnFilter",
      valueGetter: statusValueGetter,
      sortable: true,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Discount",
      minWidth: 110,
      cellClass: "no-borders",
      width: 120,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Subtotal",
      minWidth: 110,
      cellClass: "no-borders",
      width: 120,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Tax",
      minWidth: 110,
      cellClass: "no-borders",
      width: 120,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Total",
      headerName: "Total",
      minWidth: 110,
      cellClass: "no-borders",
      width: 120,
      filter: "agNumberColumnFilter",
      sortable: true,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "Balance",
      headerName: "Balance",
      minWidth: 110,
      cellClass: "no-borders",
      width: 120,
      filter: "agNumberColumnFilter",
      sortable: true,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "Created",
      cellClass: "no-borders",
      sort: "desc",
      minWidth: 110,
      width: 120,
      sortable: true,
      getQuickFilterText: (params) => {
        return params.value;
      },
    },
    {
      field: "Actions",
      headerName: "",
      minWidth: 110,
      cellRenderer: ActionRenderer,
      cellRendererParams: { gridRef: gridRef, rowData: rowData, setRowData },
      autoHeight: true,
      cellClass: "d-flex flex-row-reverse",
    },
  ]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setInvoicesList(invoiceState.data);
  }, [invoiceState.data]);

  const init = async () => {
    const response = await getInvoicesData();
    dispatch(setInvoicesListData(response));
  };

  const getInvoicesData = async () => {
    if (isSuperAdmin(userType)) {
      return await fetchInvoices();
    }

    if (isABMUser(userType)) {
      return selectedAccountState.accountId === null
        ? await fetchInvoicesFiltered(accountId)
        : await fetchInvoicesFiltered(selectedAccountState.accountId);
    }

    return await fetchInvoicesFiltered(accountId);
  };

  useEffect(() => {
    let data = [];
    _.each(invoicesList, (currentData, index) => {
      data.push({
        id: currentData.invoiceId,
        "Invoice Id": `INV${String(currentData.invoiceId).padStart(4, "0")}`,
        index: index + 1,
        ProfilePicture: { url: "/images/profile-picture-fallback.png" },
        "Quote Name": currentData.quoteName,
        Customer: currentData.customer.customerName,
        Status: currentData.status,
        Created: formatDateOnly(currentData.invoiceDate),
        Discount: formatCurrency(currentData.discount),
        Subtotal: formatCurrency(currentData.subtotal),
        Balance: getInvoiceBalance(currentData),
        Total: currentData.totalInvoice,
        Tax: formatCurrency(currentData.totalTax),
      });
    });
    setRowData(data);
  }, [invoicesList]);

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
    statusFilter(gridRef);
  }, []);

  const filterData = (searchQuery) => {
    gridRef.current.api.setQuickFilter(searchQuery);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const exportDataAsCSV = useCallback(() => {
    const params = {
      columnKeys: [
        "Customer",
        "Invoice Id",
        "Quote Name",
        "Status",
        "Discount",
        "Subtotal",
        "Total",
        "Tax",
        "Balance",
        "Created",
      ],
      fileName: `invoices-${moment(new Date()).format("YYYY-MM-DD")}.csv`,
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, []);

  return (
    <div>
      <div className="content-header-container d-flex flex-row align-items-center">
        <div className="me-auto">
          <ContentHeader
            title="Invoices"
            dataCount={rowData.length}
            filterData={filterData}
          />
        </div>
        <button
          className="btn btn-lg btn-primary me-2 "
          onClick={exportDataAsCSV}
        >
          Export as CSV
        </button>
      </div>
      <div className="content-body-container row">
        <div className="col-12">
          <div className={`ag-theme-alpine content-section-container`}>
            <AgGridReact
              className="no-header"
              rowData={rowData}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onColumnSizeChanged={onColumnsSizeChanged}
              onGridSizeChanged={onGridSizeChanged}
              rowHeight={70}
              rowSelection={"single"}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoices;
