import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactDatePicker from "react-datepicker";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Select from "react-select";
import { selectStyle } from "../../../utils/styleUtils";
import { fetchUsersDropdown } from "../../../services/userServices";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { useSelector } from "react-redux";
//import { downloadReportWithBase64, downloadPaystubsInZip } from '../../../utils/payrollRunUtils';
import { formatLabel } from "../../../utils/formatUtils";
import { createT4ReportFile } from "../../../services/reportServices";

function T4Report({
  modal,
  toggle,
  args,
  year,
  reportLabel,
  showUserField,
  setShowUserField,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm();

  const [userOptions, setUserOptions] = useState([]);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const accountId = useSelector((state) => state.auth.user.accountId);

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserList();
    };
    fetchData();
  }, []);

  const fetchUserList = async () => {
      try {
          const response = await fetchUsersDropdown();
          const users = response.map((user) => ({
              value: user.userId,
              label: `${user.firstName} ${user.lastName}`,
          }));
          // Add the "All Employees" item at the beginning of the list
          users.unshift({
              value: -1,
              label: "All Employees",
          });
          setUserOptions(users);
      } catch (error) {
          console.error("Error fetching user list:", error);
      }
  };

  const onSubmit = async (payload) => {
    let response = null;
    var userId = payload.userId != null ? payload.userId.value : null;

    // Setup report payload
    const reportPayload = {
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      t4Year: moment(`${payload.year}-01-01`).format("YYYY-MM-DD"),
      userId: userId,
    };

    // Create T4 Report csv file
    response = await createT4ReportFile(reportPayload);

    // Manage csv file properties
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "EmployeeReport.csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);

    //Close the modal after the report is generated
    toggle(false);
  };

  useEffect(() => {
    fetchUserList();
  }, [selectedAccountState])

  return (
    <Modal isOpen={modal}>
      <ModalHeader toggle={() => toggle(null)}>
        {reportLabel !== null ? reportLabel + " Report" : "T4"}
      </ModalHeader>
      <ModalBody>
        <form
          className="row form-container justify-content-center align-items-center"
          id="create-report"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col-7">
            <label htmlFor="userId" className="form-label">
              Employee
            </label>
            <div className="custom-selector" style={{ maxWidth: "230px" }}>
              <Controller
                name="userId"
                control={control}
                rules={{ required: "Please select an employee" }}
                render={({ field }) => (
                  <Select
                    onChange={field.onChange}
                    value={field.value}
                    options={userOptions}
                    className="expense-select mb-1"
                    placeholder={"Select an Employee"}
                    id="accountId"
                    styles={selectStyle}
                    isClearable
                    tabIndex={1}
                  />
                )}
              />
              <small className="form-error-message">
                {errors?.userId && errors.userId.message}
              </small>
            </div>
          </div>

          <div className="col-7 mb-3">
            <label htmlFor="year" className="form-label">
              Year
            </label>
            <div style={{ maxWidth: "230px" }}>
              <Controller
                name="year"
                control={control}
                defaultValue="" // No default year value
                rules={{ required: "Please specify a year" }}
                render={({ field }) => (
                  <select
                    {...field}
                    className="form-select"
                    defaultValue="" // No default value selected
                  >
                    <option value="" disabled hidden>
                      Select a Year
                    </option>
                    {Array.from(
                      { length: new Date().getFullYear() - 2023 + 1 },
                      (_, i) => {
                        const year = 2023 + i;
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      }
                    )}
                  </select>
                )}
              />
            </div>
            <small className="form-error-message">
              {errors?.year && errors.year.message}
            </small>
          </div>

          <div className="d-flex flex-row-reverse align-items-center mt-3">
            <button
              className="btn btn-primary ms-2"
              form="create-report"
              type="submit"
              style={{ width: "130px", padding: "8px 20px" }}
            >
              Download CSV
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={toggle}
              style={{ width: "80px", padding: "8px 20px" }}
            >
              Cancel
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

export default T4Report;