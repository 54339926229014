import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { fetchPayrollRunLineItemsByUserAndDateRange } from "../../../services/payrollRunServices";
import { fetchUsersDropdown } from "../../../services/userServices";
import {
  downloadPaystubsInZip,
  downloadReportWithBase64,
} from "../../../utils/payrollRunUtils";
import { singleDateCheck } from "../../../utils/reportUtils";
import { selectStyle } from "../../../utils/styleUtils";

function EmployeePaystubReport({
  modal,
  toggle,
  args,
  printReportList,
  dateRange,
  reportLabel,
  showUserField,
  setShowUserField,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    setFocus,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm();
  useEffect(() => {
    if (!singleDateCheck(reportLabel)) {
      setValue("reportDateRange", dateRange);
    } else {
      setValue("reportStartDate", dateRange[0]);
    }
  }, [reportLabel]);
  const [userOptions, setUserOptions] = useState([]);
  const [payrollRunLineItems, setPayrollRunLineItems] = useState([]);
  const [hasSearched, setHasSearched] = useState();
  const [employeeName, setEmployeeName] = useState("");
  const [reportStartDate, setReportStartDate] = useState();
  const [reportEndDate, setReportEndDate] = useState();
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const accountId = useSelector((state) => state.auth.user.accountId);

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserList();
    };
    fetchData();
  }, []);

  const fetchUserList = async () => {
    var response = await fetchUsersDropdown();
    const users = response.map((user, index) => ({
      value: user.userId,
      label: `${user.firstName} ${user.lastName}`,
    }));
    setUserOptions(users);
  };

  const downloadPaystubs = async () => {
    if (payrollRunLineItems.length > 0) {
      if (payrollRunLineItems.length > 4) {
        downloadPaystubsInZip(payrollRunLineItems, employeeName, reportEndDate);
      } else {
        payrollRunLineItems.forEach(async (payrollRunLineItem) => {
          await downloadReportWithBase64(payrollRunLineItem, reportEndDate);
        });
      }
    }
  };

  const onSubmit = async (payload) => {
    let response = null;
    var reportStartDate =
      payload.reportDateRange != null
        ? moment(payload.reportDateRange[0]).format("YYYY-MM-DD")
        : null;
    var reportEndDate =
      payload.reportDateRange != null
        ? moment(payload.reportDateRange[1]).format("YYYY-MM-DD")
        : null;
    var userId = payload.userId != null ? payload.userId.value : null;
    const reportPayload = {
      accountId:
        selectedAccountState.accountId === null
          ? accountId
          : selectedAccountState.accountId,
      userId: userId,
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
    };

    response = await fetchPayrollRunLineItemsByUserAndDateRange(reportPayload);
    if (response.ok) {
      const data = await response.json();
      setPayrollRunLineItems(
        data.filter((lineItem) => {
          const totalUnitsOfPay = lineItem.payrollRunLineItemPayItem.reduce(
            (sum, payItem) =>
              sum + payItem.payMethodType === 0 ? 1 : payItem.unitsOfPay,
            0
          );
          const totalPayPerUnit = lineItem.payrollRunLineItemPayItem.reduce(
            (sum, payItem) => sum + payItem.payPerUnit,
            0
          );
          return totalUnitsOfPay > 0 && totalPayPerUnit > 0;
        })
      );
      setHasSearched(true);
      setEmployeeName(payload.userId.label);
      setReportStartDate(reportStartDate);
      setReportEndDate(reportEndDate);
    }
  };

  useEffect(() => {
    if (!modal) {
      setHasSearched(false);
      setEmployeeName("");
      setPayrollRunLineItems([]);
      setValue("reportDateRange", [
        new Date(new Date().getFullYear(), 0, 1),
        new Date(),
      ]);
      setValue("userId", null);
    }
  }, [modal]);

  useEffect(() => {
    if (!modal) {
      setPayrollRunLineItems([]);
      setValue("reportDateRange", [
        new Date(new Date().getFullYear(), 0, 1),
        new Date(),
      ]);
    }
  }, [watch]);

  useEffect(() => {
    fetchUserList();
  }, [selectedAccountState]);

  return (
    <Modal isOpen={modal} toggle={() => toggle(null)} {...args}>
      <ModalHeader toggle={() => toggle(null)}>
        {reportLabel !== null ? reportLabel + " Report" : "Employee Paystubs"}
      </ModalHeader>
      <ModalBody>
        <form
          className="row col-12 mb-2 form-container justify-content-center align-items-center"
          id="create-report"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col-7 my-3">
            <label htmlFor="userId" className="form-label">
              Employee
            </label>
            <div className="custom-selector">
              <Controller
                name="userId"
                control={control}
                rules={{ required: "Please select an employee" }}
                render={({ field }) => (
                  <Select
                    onChange={field.onChange}
                    value={field.value}
                    options={userOptions}
                    className="expense-select mb-1"
                    placeholder={"Enter user"}
                    id="accountId"
                    styles={selectStyle}
                    isClearable
                    tabIndex={1}
                  />
                )}
              />
              <small className="form-error-message">
                {errors?.userId && errors.userId.message}
              </small>
            </div>
          </div>
          <div className="col-7 my-3">
            <>
              <label htmlFor="reportDateRange" className="form-label">
                Date Range
              </label>
              <Controller
                control={control}
                name="reportDateRange"
                id="reportDateRange"
                rules={{ required: "Please select the date range" }}
                register={register}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <DateRangePicker
                    id="reportDateRange"
                    name="reportDateRange"
                    className="daterange-picker mb-1"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <small className="form-error-message">
                {errors?.reportDateRange && errors.reportDateRange.message}
              </small>
            </>
          </div>
          <div className="d-flex flex-row-reverse align-items-center mt-3">
            <button
              className="btn btn-primary ms-2"
              form="create-report"
              type="submit"
            >
              Search
            </button>
          </div>
        </form>
        <div className="row col-12 form-container justify-content-center align-items-center">
          {employeeName !== "" && (
            <div className="form-label me-auto">{employeeName}</div>
          )}
          {payrollRunLineItems?.length > 0 ? (
            payrollRunLineItems.map((payrollRunLineItem, index) => (
              <div key={index} className="col-12 mb-3">
                <div className="card shadow-sm border-0">
                  <div className="card-body py-1">
                    <div className="d-flex align-items-center">
                      <div className="me-auto p-2">
                        <span className="fw-bold me-3">{index + 1}.</span>
                        {/* // TODO: Unhide for Payroll run types */}
                        {/* &nbsp;{formatLabel(payrollRunLineItem.payrollRun.payrollRunType, PAYROLL_RUN_TYPES) + " - " + payrollRunLineItem.payrollRun.endDate} */}
                        &nbsp;Payroll Run -
                        <b>{payrollRunLineItem.payrollRun.endDate}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12 mb-3 text-center">
              {hasSearched ? "No paystubs available" : "Search for Employee"}
            </div>
          )}
          <div className="d-flex flex-row-reverse align-items-center mt-3">
            <button
              className="btn btn-primary ms-2"
              type="button"
              onClick={downloadPaystubs}
              disabled={!(payrollRunLineItems.length > 0)}
            >
              Download
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default EmployeePaystubReport;
