import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { showToast } from "../../utils/toastUtils";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { provinceCheck } from "../../utils/provinceUtils";
import { fetchItemList } from "../../services/quoteServices";
import { selectStyle } from "../../utils/styleUtils";
import { addExpense, updateExpense } from "../../services/expenseServices";
import ModalRender from "../Commons/ModalRender";
import {
  fetchGLCodesByUserType,
  fetchGLCodesFiltered,
} from "../../services/glCodeServices";
import { NumericFormat } from "react-number-format";
import { removeNonDigits, removeNumberFormat } from "../../utils/formatUtils";
import { useSelector } from "react-redux";
import { TAX_TYPES, PAYMENT_TYPES, EXPENSE_ITEM_TYPES } from "../../constants";
import { isABMUser } from "../../utils/roleUtils";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

function ExpenseForm({
  isFormEdit = false,
  selectedExpense = {},
  isFormHidden,
  fillExpenseList,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  accountProvince,
  focusOnOpenOrCloseButton,
}) {
  var options = [];
  const accountId = useSelector((state) => state.auth.user.accountId);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    reset,
    getValues,
    setFocus,
    trigger,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      expenseDate: new Date(),
    },
  });
  const [isManualFormHidden] = useState(true);
  const [itemList, setItemList] = useState([]);
  const [gLCode, setGLCode] = useState("");
  const [gLCodeList, setGLCodeList] = useState([]);
  const [disabledtaxRateField, setDisabledTaxRateField] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [newOption, setNewOption] = useState("");
  const [selectedCompanyOption, setSelectedCompanyOption] = useState(null);
  const [selectedExpenseTemplate, setSelectedExpenseTemplate] = useState(null);
  const [isDropdownDisabled, setDropdownDisabled] = useState(true);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const accountState = useSelector((state) => state.account.selectedAccount);
  const userType = useSelector((state) => state.auth.user.userType);
  const customerState = useSelector((state) => state.customer);

  if (itemList !== undefined) {
    options = itemList.map((item, index) => {
      let value = "";
      let itemType = "";

      if (item.expenseItemId !== undefined) {
        value = EXPENSE_ITEM_TYPES[0].label + "-" + item.expenseItemId;
        itemType = EXPENSE_ITEM_TYPES[0].value;
      } else if (item.productMaterialId !== undefined) {
        value = EXPENSE_ITEM_TYPES[1].label + "-" + item.productMaterialId;
        itemType = EXPENSE_ITEM_TYPES[1].value;
      } else if (item.labourId !== undefined) {
        value = EXPENSE_ITEM_TYPES[2].label + "-" + item.labourId;
        itemType = EXPENSE_ITEM_TYPES[2].value;
      }

      return {
        index: index,
        value,
        itemType,
        label:
          item.labourDescription !== undefined
            ? item.labourDescription
            : item.expenseDescription,
      };
    });
  }

  const expenseDateRef = useRef(null);

  //Field watchers
  const quantity = watch("quantity");
  const quantityMobile = watch("quantityMobile");
  const rate = watch("rate");
  const taxRate = watch("taxRate");
  const discount = watch("discount");
  const taxType = watch("taxType");
  const expenseTemplate = watch("expenseTemplate");

  useEffect(() => {
    if (isFormEdit) {
      setGLCode(parseInt(selectedExpense["glCodeId"]));
      setValue("taxType", parseInt(selectedExpense["taxType"]));
      setValue("paymentType", parseInt(selectedExpense["paymentType"]));
      setValue("taxRate", parseFloat(selectedExpense["taxRate"]) * 100);
      setValue("glCodeId", parseInt(selectedExpense["glCodeId"]));
      setValue("quantity", parseFloat(selectedExpense["quantity"]));
      setValue("subtotal", parseFloat(selectedExpense["subtotal"]).toFixed(2));
      setValue("total", parseFloat(selectedExpense["total"]).toFixed(2));
      setValue("totalTax", selectedExpense["totalTax"].toFixed(2));
      setValue("rate", parseFloat(selectedExpense["rate"]).toFixed(2));
      setValue("discount", parseFloat(selectedExpense["discount"]).toFixed(2));
      setValue("expenseDate", moment(selectedExpense["expenseDate"]).clone().hour(7).minute(0).second(0).toDate());
      setDropdownDisabled(selectedExpense["itemId"] > 0 ? false : true);

      if (selectedExpense["itemId"] > 0) {
        let value = "";
        const item = _.find(itemList, (item) => {
          switch (selectedExpense["itemType"]) {
            case 1:
              return item.expenseItemId === selectedExpense["itemId"];
            case 2:
              return item.productMaterialId === selectedExpense["itemId"];
            case 3:
              return item.labourId === selectedExpense["itemId"];
            default:
              setDropdownDisabled(true);
              return null;
          }
        });

        if (_.isNil(item)) {
          return;
        }

        if (item?.expenseItemId !== undefined) {
          value = item?.expenseItemId;
        } else if (item?.productMaterialId !== undefined) {
          value = item?.productMaterialId;
        } else if (item?.labourId !== undefined) {
          value = item?.labourId;
        }

        setSelectedExpenseTemplate({
          value,
          label:
            item?.labourDescription !== undefined
              ? item?.labourDescription
              : item?.expenseDescription,
        });
      }

      const selectedCompany = _.find(companyOptions, (companyOption) => {
        return companyOption.label === selectedExpense.companyName;
      });

      setSelectedCompanyOption({
        value: selectedCompany?.value || 0,
        label: selectedCompany?.label || selectedExpense.companyName,
      });
    } else {
      setGLCode("");
      setValue("glCodeId", "");
      setSelectedCompanyOption(null);
      setValue("company", "");
      reset();
      setValue("discount", 0);
      setValue("rate", 0);
      setValue("subtotal", 0);
      setValue("totalTax", 0.0);
    }
  }, [selectedExpense["expenseId"]]);

  /* useEffects */
  useEffect(() => {
    clearErrors();
    fillItemList();
    fillGLCode();
  }, []);

  // clears values on form type change
  useEffect(() => {
    reset();
    setValue("quoteItemId", 0);
    setValue("quantity", 0);
    setValue("quantityMobile", 0);
    setValue("discount", 0);
    setValue("rate", 0);
    setValue("taxRate", 0);
    setGLCode("");
    setValue("glCodeId", "");
    setSelectedCompanyOption(null);
    setValue("company", "");
    setDropdownDisabled(true);
    setSelectedExpenseTemplate(null);
  }, [isManualFormHidden]);

  useEffect(() => {
    clearErrors();
    reset();
    setValue("taxType", 0);
    setValue("taxRate", 0);
    setValue("rate", 0);
    setValue("discount", 0);
    setValue("totalTax", 0);
    setValue("subtotal", 0);
    setValue("total", 0);
    setGLCode("");
    setValue("glCodeId", "");
    setSelectedCompanyOption(null);
    setValue("company", "");
    setDropdownDisabled(true);
    setSelectedExpenseTemplate(null);

    if (!isFormHidden) {
      setTimeout(() => {
        setFocus("expenseDate");
      }, 50);
    }
  }, [isFormHidden]);

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (!_.isEmpty(customerState)) {
      const customers = customerState.data.map((customer, index) => ({
        value: customer.customerId,
        label: customer.customerName,
      }));

      setCompanyOptions(customers);
    }
  }, [customerState]);

  const fillItemList = async () => {
    var currentUserId = accountId;
    if (isABMUser(userType) && selectedAccountState.accountId !== null) {
      currentUserId = selectedAccountState.accountId;
    }
    const data = await fetchItemList(currentUserId);
    const labour = data.labour;
    const material = data.productMaterialItem;
    const expense = data.expenseItem;
    var items = {};
    items = [...labour, ...material, ...expense];
    setItemList(items);
  };

  const fillGLCode = async (id) => {
    const accountTypeId = parseInt(
      process.env.REACT_APP_ACCOUNT_TYPE_COST_OF_GOODS_SOLD
    );
    const response = await fetchGLCodesFiltered();

    const filteredGLCodes = response.filter(
      (gl) => gl.glCodeName >= 5000 && gl.glCodeName < 6000
    );

    setGLCodeList(filteredGLCodes);
  };

  const numberCheck = (value) => {
    return value > 0 ? value : 0;
  };

  // Calculations when creating an item manually
  useEffect(() => {
    // var taxRateCheck = 0
    const quantityCheck = numberCheck(quantity);
    const rateCheck = numberCheck(parseFloat(removeNumberFormat(rate)));
    const discountCheck = numberCheck(parseFloat(removeNumberFormat(discount)));
    const taxRateCheck = numberCheck(
      parseFloat(removeNumberFormat(taxRate) / 100)
    );

    const subtotal = parseFloat(quantityCheck * rateCheck) - discountCheck;
    const totalTax = parseFloat(subtotal * taxRateCheck);
    const total = parseFloat(subtotal + totalTax);

    setValue("subtotal", numberCheck(subtotal).toFixed(2));
    setValue("total", numberCheck(total).toFixed(2));
    setValue("totalTax", numberCheck(totalTax).toFixed(2));
  }, [quantity, rate, taxRate, discount]);

  useEffect(() => {
    setValue("quantity", quantityMobile);
  }, [quantityMobile]);

  const onSubmit = async (payload) => {
    let response = null;
    const expenseTemplate = payload.expenseTemplate;
    _.set(payload, "rate", removeNumberFormat(payload.rate));
    _.set(payload, "discount", removeNumberFormat(payload.discount));
    _.set(payload, "subtotal", removeNumberFormat(payload.subtotal));
    _.set(payload, "total", removeNumberFormat(payload.total));
    _.set(payload, "totalTax", removeNumberFormat(payload.totalTax));
    _.set(payload, "taxRate", removeNumberFormat(payload.taxRate) / 100);
    _.set(payload, "expenseTemplate", null);
    _.set(payload, "companyName", selectedCompanyOption.label);
    _.set(
      payload,
      "itemId",
      !isDropdownDisabled
        ? removeNonDigits(selectedExpenseTemplate?.value) || 0
        : 0
    );
    _.set(
      payload,
      "itemType",
      !isDropdownDisabled ? selectedExpenseTemplate?.itemType || 0 : 0
    );
    _.set(
      payload,
      "customerId",
      selectedCompanyOption.value > 0 ? selectedCompanyOption.value : null
    );
    var currentDate = moment().clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    _.set(payload, 'currentDate', currentDate);

    if (isFormEdit) {
      _.set(payload, "expenseId", selectedExpense.expenseId);
      _.set(payload, "accountId", accountId);
      if (isABMUser(userType) && selectedAccountState.accountId !== null) {
        _.set(payload, "accountId", selectedAccountState.accountId);
      }

      response = await updateExpense(payload);
      showToast({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Successfully updated" : "Failed to update"
          } expense.`,
      });
    } else {
      _.set(payload, "balance", removeNumberFormat(payload.total));
      _.set(payload, "expenseId", 0);

      var expenseDate = moment(payload.expenseDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
      _.set(payload, 'expenseDate', expenseDate);
      if (isABMUser(userType)) {
        _.set(
          payload,
          "accountId",
          accountState.accountId != null ? accountState.accountId : accountId
        );
      } else {
        _.set(payload, "accountId", accountId);
      }
      response = await addExpense(payload);
      showToast({
        type: `${response.ok ? "success" : "error"}`,
        message: `${response.ok ? "Successfully added" : "Failed to add"
          } expense.`,
      });
    }

    if (response.ok) {
      // clearErrors();
      fillExpenseList();
      toggleFormDisplay();

      if (isDirty) {
        setModal(false);
      }
      setIsFormDirty(false);
      // openOrCloseButtonRef.current.focus();
      focusOnOpenOrCloseButton();
    }
  };

  const calculateExpense = (currentTaxRate = taxRate) => {
    if (!isFormEdit) {
      const { quantity, rate, discount } = getValues();

      const quantityCheck = numberCheck(quantity);
      const rateCheck = numberCheck(parseFloat(removeNumberFormat(rate)));
      const discountCheck = numberCheck(
        parseFloat(removeNumberFormat(discount))
      );
      // const discountCheck = numberCheck(parseFloat(discount));

      const taxRateCheck = numberCheck(
        parseFloat(removeNumberFormat(currentTaxRate) / 100)
      );

      const subtotal = parseFloat(quantityCheck * rateCheck);

      const totalTax = parseFloat(
        (quantityCheck * rateCheck - discountCheck) * taxRateCheck
      );
      const total = subtotal + totalTax - discountCheck;

      setValue("subtotal", numberCheck(subtotal).toFixed(2));
      setValue("total", numberCheck(total).toFixed(2));
      setValue("totalTax", numberCheck(totalTax).toFixed(2));
    }
  };

  const setExpenseTax = (e) => {
    if (!isFormEdit) {
      const { taxType } = getValues();
      var currentTaxRate = 0;
      if (taxType !== undefined && !isNaN(taxType)) {
        if (taxType === 5) {
          if (selectedExpense?.taxType === 5) {
            setValue(
              "taxRate",
              (
                provinceCheck(
                  accountProvince,
                  taxType,
                  selectedExpense?.taxRate
                ) * 100
              ).toFixed(2)
            );
            currentTaxRate = (
              provinceCheck(
                accountProvince,
                taxType,
                selectedExpense?.taxRate
              ) * 100
            ).toFixed(2);
          } else {
            setValue(
              "taxRate",
              (provinceCheck(accountProvince, taxType) * 100).toFixed(2)
            );
            currentTaxRate = (
              provinceCheck(
                accountProvince,
                taxType,
                selectedExpense?.taxRate
              ) * 100
            ).toFixed(2);
          }
          setDisabledTaxRateField(false);
        } else {
          setDisabledTaxRateField(true);
          setValue(
            "taxRate",
            (provinceCheck(accountProvince, taxType) * 100).toFixed(2)
          );
          currentTaxRate = (
            provinceCheck(accountProvince, taxType) * 100
          ).toFixed(2);
        }
      }

      calculateExpense(currentTaxRate);
    }
  };

  const setExpenseFromDropdown = (selectOption) => {
    setSelectedExpenseTemplate(selectOption);
    if (!isFormEdit) {
      const { expenseTemplate, taxType } = getValues();

      if (
        expenseTemplate !== undefined &&
        expenseTemplate !== null &&
        TAX_TYPES !== undefined
      ) {
        const taxType = parseInt(itemList[expenseTemplate.index]["taxType"]);
        const defaultRate = parseFloat(
          itemList[expenseTemplate.index]["defaultRate"]
        );
        const glCodeId = parseFloat(
          itemList[expenseTemplate.index]["glCodeId"]
        );
        var currentTaxRate = 0;
        if (taxType !== undefined && !isNaN(taxType)) {
          if (taxType === 5) {
            if (selectedExpense?.taxType === 5) {
              setValue(
                "taxRate",
                (
                  provinceCheck(
                    accountProvince,
                    taxType,
                    selectedExpense?.taxRate
                  ) * 100
                ).toFixed(2)
              );
              currentTaxRate = (
                provinceCheck(
                  accountProvince,
                  taxType,
                  selectedExpense?.taxRate
                ) * 100
              ).toFixed(2);
            } else {
              setValue(
                "taxRate",
                (provinceCheck(accountProvince, taxType) * 100).toFixed(2)
              );
              currentTaxRate = (
                provinceCheck(
                  accountProvince,
                  taxType,
                  selectedExpense?.taxRate
                ) * 100
              ).toFixed(2);
            }
            setDisabledTaxRateField(false);
          } else {
            setDisabledTaxRateField(true);
            setValue(
              "taxRate",
              (provinceCheck(accountProvince, taxType) * 100).toFixed(2)
            );
            currentTaxRate = (
              provinceCheck(accountProvince, taxType) * 100
            ).toFixed(2);
          }
        }

        setValue("description", expenseTemplate.label);
        setValue("quantity", 0);
        setValue("rate", 0);
        setValue("taxType", taxType);
        setValue("taxRate", currentTaxRate);
        setValue("glCodeId", glCodeId);
        setValue("discount", 0);
        setValue("subtotal", 0);
        setValue("total", 0);
        setValue("totalTax", 0);
        setValue("discount", 0);

        setGLCode(parseInt(glCodeId));
      } else {
        setValue("description", undefined);
        setValue("quantity", undefined);
        setValue("rate", undefined);
        setValue("taxType", "");
        setValue("taxRate", undefined);
        setValue("discount", undefined);
        setValue("subtotal", 0);
        setValue("total", 0);
        setValue("totalTax", 0);
        setValue("discount", 0);
        // setValue('accountId', accountId);

        setGLCode("");
      }
    }
  };

  const handleCompanyNameKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      // Check if it already exists
      const companyExists = _.some(companyOptions, (item) =>
        _.includes(item.label.toLowerCase(), newOption.toLowerCase())
      );
      if (!companyExists) {
        // Create a new option from the typed characters
        const newOptionObject = { value: null, label: newOption };

        // Update the options state with the new option
        setCompanyOptions([...companyOptions, newOptionObject]);

        // Set the selected option to the newly added option
        setSelectedCompanyOption(newOptionObject);

        // Clear the input field
        setNewOption("");
      }
    }
  };

  const handleCompanyNameChange = (selectedOption) => {
    setSelectedCompanyOption(selectedOption);
    setValue("company", selectedOption?.label);

    // Manually trigger revalidation after setting the value
    trigger("company");
  };

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setDropdownDisabled(value === "manualExpense");
  };

  return (
    <div className="content-section-container">
      <div className="d-flex flex-row align-items-center mb-5">
        <div className="form-title me-auto">
          {`${isFormEdit ? "Review Posted" : "Add"} Expense`}
        </div>
        <button
          className={`btn btn-primary ${isFormEdit ? "d-none" : ""}`}
          form="expense-form"
          type="submit"
          disabled={isFormEdit}
          tabIndex={14}
        >
          Save
        </button>
      </div>
      <form
        className="form-container"
        id="expense-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col-12 col-sm-4 mb-4">
            <label htmlFor="expenseDate" className="form-label">
              Expense Date
            </label>
            <Controller
              control={control}
              name="expenseDate"
              register={register}
              rules={{ required: "Expense date is required" }}
              // render={({ field: { onChange, onBlur, value, ref } }) => (
              render={({ field }) => (
                <ReactDatePicker
                  className="datepicker-field"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  selected={field.value}
                  dateFormat="yyyy-MM-dd"
                  id="expenseDate"
                  disabled={isFormEdit}
                  tabIndex={1}
                  ref={(elem) => {
                    elem && field.ref(elem.input);
                  }}
                />
              )}
            />
            <small className="form-error-message">
              {errors?.payroll?.payStartDate &&
                errors.payroll?.payStartDate.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="description" className="form-label">
              Company
            </label>
            <div className="custom-selector">
              <Controller
                name="company"
                control={control}
                register={register}
                rules={{ required: "Company is required" }}
                render={({ field }) => (
                  <CreatableSelect
                    {...field}
                    onChange={handleCompanyNameChange}
                    value={selectedCompanyOption}
                    options={companyOptions}
                    className="company-select"
                    classNamePrefix="react-select"
                    placeholder={"Manually Enter Company"}
                    id="company"
                    styles={selectStyle}
                    isClearable
                    isDisabled={isFormEdit}
                    tabIndex={2}
                  />
                )}
              />
            </div>
            <small className="form-error-message">
              {errors?.company && errors.company.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="paymentType" className="form-label">
              Mode of Payment
            </label>
            <select
              className="form-select"
              id="paymentType"
              disabled={isFormEdit}
              tabIndex={3}
              {...register("paymentType", {
                valueAsNumber: true,
                required: "Mode of Payment is required",
              })}
            >
              <option key="none" value="" hidden>
                Select Mode
              </option>
              {PAYMENT_TYPES.map((paymentType, index) => (
                <option key={index} value={paymentType.value}>
                  {paymentType.label}
                </option>
              ))}
            </select>
            <small className="form-error-message">
              {errors?.paymentType && errors.paymentType.message}
            </small>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6 col-sm-4">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="radioGroup"
                id="radioButtonManualExpense"
                value="manualExpense"
                onChange={handleRadioChange}
                checked={isDropdownDisabled}
                disabled={isFormEdit}
                tabIndex={4}
              />
              <label
                className="form-check-label"
                htmlFor="radioButtonManualExpense"
              >
                Manually Enter Expense
              </label>
            </div>
          </div>
          <div className="col-6 col-sm-4">
            <div className="d-flex flex-row align-items-center">
              <div className="me-1">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="radioGroup"
                    id="radioButtonExpenseDropdown"
                    value="selectExpenseTemplate"
                    onChange={handleRadioChange}
                    checked={!isDropdownDisabled}
                    disabled={isFormEdit}
                    tabIndex={5}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="radioButtonExpenseDropdown"
                  ></label>
                </div>
              </div>
              <div className="ms-1 w-100">
                <div className="custom-selector">
                  <Controller
                    name="expenseTemplate"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={options}
                        className="expense-select"
                        classNamePrefix="react-select"
                        placeholder={"Select Expense Template"}
                        onChange={(e) => {
                          field.onChange(e);
                          setExpenseFromDropdown(e);
                        }}
                        id="expense"
                        styles={selectStyle}
                        isClearable
                        isDisabled={isDropdownDisabled || isFormEdit}
                        tabIndex={6}
                        value={selectedExpenseTemplate}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              id="description"
              disabled={isFormEdit}
              tabIndex={7}
              defaultValue={
                isFormEdit ? selectedExpense?.description || "" : ""
              }
              {...register("description", {
                required: "Description is required",
              })}
            />
            <small className="form-error-message">
              {errors?.description && errors.description.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="referenceId" className="form-label">
              Reference ID
            </label>
            <input
              type="text"
              className="form-control"
              id="referenceId"
              disabled={isFormEdit}
              tabIndex={8}
              defaultValue={
                isFormEdit ? selectedExpense?.referenceId || "" : ""
              }
              {...register("referenceId")}
            />
            <small className="form-error-message">
              {errors?.referenceId && errors.referenceId.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="glCodeId" className="form-label">
              GL Code:
            </label>
            <select
              className="form-control"
              id="glCodeId"
              value={gLCode}
              disabled={isFormEdit}
              tabIndex={9}
              {...register("glCodeId", {
                valueAsNumber: true,
                onChange: (e) => setGLCode(e.target.value),
                required: "GL code is required",
              })}
            >
              <option key="none" value="" hidden>
                GL Code
              </option>
              {gLCodeList.map((gl, index) => (
                <option key={index} value={gl.glCodeId}>
                  {gl.glCodeName} - {gl.description}
                </option>
              ))}
            </select>
            <small className="form-error-message">
              {errors?.glCodeId && errors.glCodeId.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="quantity" className="form-label">
              Quantity
            </label>
            <input
              type="number"
              step="any"
              className="form-control"
              id="quantity"
              disabled={isFormEdit}
              tabIndex={10}
              defaultValue={isFormEdit ? selectedExpense?.quantity : ""}
              {...register("quantity", {
                valueAsNumber: true,
                required: "Quantity is required",
                onChange: calculateExpense,
              })}
            />
            <small className="form-error-message">
              {errors?.quantity && errors.quantity.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="rate" className="form-label">
              Unit Price
            </label>
            <Controller
              control={control}
              name="rate"
              register={register}
              rules={{ required: "Unit Price is required" }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat
                  name={name}
                  defaultValue={value}
                  value={value}
                  onValueChange={({ floatValue }) => {
                    // Update the form value using setValue
                    setValue(name, floatValue);
                    calculateExpense();
                  }}
                  // onChange={onChange}
                  onBlur={onBlur}
                  className="form-control"
                  prefix="$"
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  id="rate"
                  mask="_"
                  decimalScale={2}
                  fixedDecimalScale
                  disabled={isFormEdit}
                  tabIndex={11}
                />
              )}
            />
            <small className="form-error-message">
              {errors?.rate && errors.rate.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="subtotal" className="form-label">
              Subtotal
            </label>
            <Controller
              control={control}
              name="subtotal"
              register={register}
              rules={{ valueAsNumber: true }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  className="form-control"
                  prefix="$"
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  id="subtotal"
                  mask="_"
                  decimalScale={2}
                  disabled
                  fixedDecimalScale
                />
              )}
            />
            <small className="form-error-message">
              {errors?.subtotal && errors.subtotal.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="taxType" className="form-label">
              Tax Type
            </label>
            <select
              className="form-select"
              id="taxType"
              disabled={isFormEdit}
              tabIndex={12}
              {...register("taxType", {
                valueAsNumber: true,
                required: "Tax Type is required",
                onChange: (e) => {
                  setExpenseTax(e);
                },
              })}
            >
              <option key="none" value="" hidden>
                Tax Type
              </option>
              {TAX_TYPES.map((tax, index) => (
                <option key={index} value={tax.value}>
                  {tax.label}
                </option>
              ))}
            </select>
            <small className="form-error-message">
              {errors?.taxType && errors.taxType.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="taxRate" className="form-label">
              Tax Rate
            </label>
            <Controller
              control={control}
              name="taxRate"
              register={register}
              rules={{ valueAsNumber: true }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat
                  value={value}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  className="form-control"
                  suffix="%"
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  id="taxRate"
                  mask="_"
                  disabled={disabledtaxRateField}
                  onValueChange={({ floatValue }) => {
                    // Update the form value using setValue
                    setValue(name, floatValue);
                    calculateExpense();
                  }}
                />
              )}
            />
            <small className="form-error-message">
              {errors?.taxRate && errors.taxRate.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="totalTax" className="form-label">
              Total Tax
            </label>
            <Controller
              control={control}
              name="totalTax"
              register={register}
              rules={{ valueAsNumber: true }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  className="form-control"
                  prefix="$"
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  id="totalTax"
                  mask="_"
                  decimalScale={2}
                  disabled
                  fixedDecimalScale
                />
              )}
            />
            <small className="form-error-message">
              {errors?.accountName && errors.accountName.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 offset-sm-8 mb-2">
            <label htmlFor="discount" className="form-label">
              Discount
            </label>
            <Controller
              control={control}
              name="discount"
              register={register}
              rules={{ required: "Discount is required" }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat
                  value={value === undefined ? "" : value}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  className="form-control"
                  prefix="$"
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  id="discount"
                  mask="_"
                  decimalScale={2}
                  fixedDecimalScale
                  disabled={isFormEdit}
                  tabIndex={13}
                />
              )}
            />
            <small className="form-error-message">
              {errors?.discount && errors.discount.message}
            </small>
          </div>
          <div className="col-6 col-sm-4 offset-sm-8 mb-2">
            <label htmlFor="total" className="form-label">
              Total
            </label>
            <Controller
              control={control}
              name="total"
              register={register}
              rules={{ valueAsNumber: true }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  className="form-control"
                  prefix="$"
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                  id="total"
                  mask="_"
                  decimalScale={2}
                  disabled
                  fixedDecimalScale
                />
              )}
            />
            <small className="form-error-message">
              {errors?.accountName && errors.accountName.message}
            </small>
          </div>
        </div>
      </form>
      <ModalRender
        modal={modal}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        setModal={setModal}
        toggle={toggle}
        isFormEdit={isFormEdit}
        formType={"expense"}
        toggleFormDisplay={toggleFormDisplay}
      />
    </div>
  );
}

export default ExpenseForm;
