import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { fetchGLCodesFiltered } from "../../services/glCodeServices";
import {
  fetchItemList,
  fetchQuoteData,
  fetchTableData,
  fetchUpdateQuoteItem,
  fetchUpdateQuoteNote,
  fetchUpdateQuoteStatus,
} from "../../services/quoteServices";
import {
  createQuoteItemsReportFile,
  emailQuoteItemsReport,
} from "../../services/reportServices";
import { formatTaxRate } from "../../utils/formatUtils";
import { printPreviewReportWithBase64 } from "../../utils/payrollRunUtils";
import { displayProvince } from "../../utils/provinceUtils";
import { currencyRendererSelector } from "../../utils/quoteUtils";
import { isSuperAdmin } from "../../utils/roleUtils";
import { showToast } from "../../utils/toastUtils";
import { NumericCellEditor } from "../Commons/Editor/NumericCellEditor";
import EditQuoteModal from "../Commons/Modals/EditQuoteModal";
import QuoteContentHeader from "../Commons/QuoteContentHeader";
import ActionRemoveRowRenderer from "../Templates/CellRenderers/ActionRemoveRowRenderer";
import QuoteTaxableRenderer from "../Templates/CellRenderers/QuoteTaxableRenderer";
import QuoteTaxableEditor from "../Templates/Editor/QuoteTaxableEditor";
import QuoteDetailFooter from "./QuoteDetailFooter";
import QuoteDetails from "./QuoteDetails";
import QuoteItemForm from "./QuoteItemForm";

function EditQuotePage(props, args) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const userType = useSelector((state) => state.auth.user.userType);
  const { state } = useLocation();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [quoteData, setQuoteData] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [estimateNo, setEstimateNo] = useState("");
  const [estimateName, setEstimateName] = useState("");
  const [estimateDate, setEstimateDate] = useState("");
  const [estimateDueDate, setEstimateDueDate] = useState("");
  const [tax, setTax] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [province, setProvince] = useState("");
  const [gLCodeList, setGLCodeList] = useState([]);
  const [status, setStatus] = useState(0);
  const [note, setNote] = useState("");
  const [accountId, setAccountId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [rowData, setRowData] = useState();
  const [inputRow, setInputRow] = useState({
    Action: "action",
    Item: "Add item:",
  });
  const [itemList, setItemList] = useState([]);
  const [editable, setEditable] = useState(true);
  const selectedAccountState = useSelector(
    (state) => state.account.selectedAccount
  );
  const [oldValues, setOldValues] = useState({
    subtotal: 0,
    discount: 0,
    total: 0,
    tax: 0,
  });
  const [printMode, setPrintMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [editQuoteModal, setEditQuoteModal] = useState(false);
  const editQuoteToggle = () => setEditQuoteModal(!editQuoteModal);

  const taxRef = useRef();
  const taxSelectRef = useRef();
  const currentProvince = useRef();
  const currentInputRow = useRef();

  //Loads data on render
  useEffect(() => {
    fillQuoteData();
    fillTableData();
  }, []);

  // Fetch after getting the accountId
  useEffect(() => {
    if (accountId !== "") {
      fillItemList();
      fillGLCode();
    }
  }, [accountId]);

  useEffect(() => {
    currentInputRow.current = inputRow;
    if (gridApi != null) {
      gridApi.refreshCells({ columns: ["Action"] });
    }
  }, [inputRow]);

  //Fetch for the customer data
  const fillQuoteData = async () => {
    const data = await fetchQuoteData(state);

    setQuoteData(data);
    setName(data?.customer?.customerName);
    setEmail(data?.customer?.email);
    setPhone(data?.customer?.phone);
    setAddress(
      data?.customer?.addressLine1 +
        ", " +
        data?.customer?.city +
        ", " +
        displayProvince(data?.customer?.province)
    );
    setEstimateNo(state?.quoteId);
    setEstimateName(data?.quoteName);
    setEstimateDate(data?.quoteDate);
    setEstimateDueDate(data?.paymentDueDate);
    setTax(data?.totalTax);
    setSubtotal(data?.subtotal);
    setTotal(data?.totalQuote);
    setDiscount(data?.discount);
    setNote(data?.notes);
    setAccountId(data?.accountId);
    setCustomerId(data?.customerId);
    setInvoiceId(data?.invoice?.invoiceId);
    setStatus(data?.status);
    setOldValues({
      subtotal: parseFloat(data?.subtotal),
      discount: parseFloat(data?.discount),
      total: parseFloat(data?.totalQuote),
      tax: parseFloat(data?.totalTax),
    });

    // if (parseInt(data.status) !== 0 || (isSuperAdmin(userType) || isABMUser(userType))) {
    if (parseInt(data?.status) !== 0 || isSuperAdmin(userType)) {
      setEditable(false);
      setColumnDefs(columnDefinitions(false));
    }

    setProvince(data?.customer?.province);
    currentProvince.current = data?.customer?.province;
  };

  //Fetch for the table data
  const fillTableData = async () => {
    const data = await fetchTableData(state);

    setRowData(
      data.map((currentData, index) => ({
        Item: currentData.quoteItemId,
        // index: index + 1,
        Description: currentData.description,
        QTY: currentData.quantity,
        "Unit Price": parseFloat(currentData.rate),
        "Tax Type": currentData.taxType,
        "Tax Rate": currentData.taxRate,
        Discount: parseFloat(currentData.discount),
        Subtotal: parseFloat(currentData.subtotal),
        "Total Tax": parseFloat(currentData.totalTax),
        Total: parseFloat(currentData.total),
        GLCodeId: parseInt(currentData.glCodeId),
        AccountId: parseInt(currentData.accountId),
        QuoteItemId: parseInt(currentData.quoteItemId),
        CustomerId: parseInt(currentData.customerId),
        QuoteId: parseInt(currentData.quoteId),
      }))
    );
  };

  const fillGLCode = async (id) => {
    const response = await fetchGLCodesFiltered();

    const filteredGLCodes = response.filter(
      (gl) => gl.glCodeName >= 4000 && gl.glCodeName < 5000
    );

    setGLCodeList(filteredGLCodes);
  };

  const fillItemList = async () => {
    const data = await fetchItemList(accountId);
    const labour = data.labour;
    const material = data.productMaterialItem;
    const expense = data.expenseItem;
    var items = {};
    items = [...labour, ...material, ...expense];
    setItemList(items);
  };

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: {
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        textAlign: "left",
      },
      resizable: true,
    };
  }, []);

  const onCellEditingStopped = useCallback(async (params) => {
    // Updates field in database when updating a cell
    if (params.node.rowPinned !== "top") {
      const selectedNode = gridRef.current.api.getSelectedNodes();

      const subtotal =
        selectedNode[0].data["QTY"] * selectedNode[0].data["Unit Price"] -
        selectedNode[0].data["Discount"];
      const totalTax = subtotal * selectedNode[0].data["Tax Rate"];
      const total = subtotal + totalTax;

      let response = null;
      //sets the data using the columns of the field you're editing
      const payload = {
        description: selectedNode[0].data["Description"],
        quantity: selectedNode[0].data["QTY"],
        discount: selectedNode[0].data["Discount"],
        rate: selectedNode[0].data["Unit Price"],
        taxType: parseInt(selectedNode[0].data["Tax Type"]),
        taxRate: selectedNode[0].data["Tax Rate"],
        total: total > 0 ? total : 0,
        subTotal: subtotal,
        totalTax: totalTax > 0 ? totalTax : 0,
        glCodeId: selectedNode[0].data["GLCodeId"],
        accountId: selectedNode[0].data["AccountId"],
        quoteItemId: selectedNode[0].data["QuoteItemId"],
        quoteId: selectedNode[0].data["QuoteId"],
      };

      response = await fetchUpdateQuoteItem(
        payload,
        selectedNode[0].data["Item"]
      );
      if (response.ok) {
        fillTableData();
        fillQuoteData();
        taxSelectRef.current.focus();
      }
      showToast({
        type: `${response.ok ? "success" : "error"}`,
        message: `${
          response.ok ? "Successfully updated" : "Failed to update"
        } quote status.`,
      });
    }
  }, []);

  //Resizes the columns to fit the whole grid
  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
    setGridApi(params.api);
  }, []);

  const columnDefinitions = (
    isEditable,
    isPrintableLayout = false,
    hideActionButtonOnly = false
  ) => {
    const defs = [
      {
        field: "Item",
        headerName: "#",
        resizable: false,
        width: 50,
        minWidth: 50,
        autoHeight: true,
        cellEditorPopup: true,
        singleClickEdit: true,
        maxWidth: isPrintableLayout ? 50 : null,
      },
      {
        field: "Description",
        minWidth: 120,
        editable: isEditable,
        maxWidth: isPrintableLayout ? 120 : null,
      },
      {
        field: "QTY",
        minWidth: 60,
        width: 60,
        editable: isEditable,
        cellEditor: NumericCellEditor,
        maxWidth: isPrintableLayout ? 60 : null,
      },
      {
        field: "Unit Price",
        minWidth: 110,
        editable: isEditable,
        cellEditor: NumericCellEditor,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null,
      },
      {
        field: "Tax Type",
        minWidth: 110,
        headerName: "Tax Type",
        singleClickEdit: true,
        cellRendererSelector: (params) => {
          if (params.data["Tax Type"] !== undefined) {
            return {
              component: QuoteTaxableRenderer,
            };
          } else {
            return undefined;
          }
        },
        cellEditor: QuoteTaxableEditor,
        cellEditorParams: {
          province: () => currentProvince.current,
          gridRef: gridRef,
        },
        editable: isEditable,
        autoHeight: true,
        maxWidth: isPrintableLayout ? 110 : null,
      },
      {
        field: "Tax Rate",
        headerName: "Tax Rate",
        valueGetter: (params) => formatTaxRate(params.data["Tax Rate"]),
        autoHeight: true,
        minWidth: 110,
        maxWidth: isPrintableLayout ? 110 : null,
      },
      {
        field: "Discount",
        minWidth: 110,
        editable: isEditable,
        cellEditor: NumericCellEditor,
        // cellEditor: NumericEditor,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null,
      },
      {
        field: "Subtotal",
        minWidth: 110,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null,
      },

      {
        field: "Total",
        minWidth: 110,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null,
      },
      {
        field: "Total Tax",
        minWidth: 110,
        cellRendererSelector: currencyRendererSelector,
        maxWidth: isPrintableLayout ? 110 : null,
      },
      {
        field: "Action",
        minWidth: 120,
        cellRenderer: ActionRemoveRowRenderer,
        cellRendererParams: {
          gridRef: gridRef,
          setRowData,
          rowData: rowData,
          fillQuoteData,
          fillTableData,
        },
        cellClass: "non-printable",
        autoHeight: true,
        maxWidth: isPrintableLayout || hideActionButtonOnly ? 0 : null,
      },
    ];

    return defs;
  };

  const [columnDefs, setColumnDefs] = useState(columnDefinitions(true));

  useEffect(() => {
    if (gridRef.current.columnApi !== undefined) {
      gridRef.current.columnApi.setColumnVisible("Action", editable);
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [columnDefs]);

  const updateQuoteStatus = async (value) => {
    const status = parseInt(value);
    let response = null;
    var quoteDate = moment()
      .clone()
      .hour(7)
      .minute(0)
      .second(0)
      .format("YYYY-MM-DD");

    // Quote date is used for creating the invoice after a quote has been approved.
    const payload = {
      status: status,
      accountId: accountId,
      quoteId: quoteData.quoteId,
      quoteDate: quoteDate,
    };

    response = await fetchUpdateQuoteStatus(payload, state.quoteId);
    showToast({
      type: `${response.ok ? "success" : "error"}`,
      message: `${
        response.ok ? "Successfully updated" : "Failed to update"
      } quote status.`,
    });
    if (response.ok) {
      setStatus(value);
      fillQuoteData();
      fillTableData();
    }
  };

  const updateNoteStatus = async (event) => {
    var value = event.target.value;
    let response = null;
    const payload = {
      notes: value,
      accountId: accountId,
      quoteId: quoteData.quoteId,
    };
    response = await fetchUpdateQuoteNote(payload, state.quoteId);
    if (response.ok) {
      setStatus(value);
      fillQuoteData();
      fillTableData();
    }
    showToast({
      type: `${response.ok ? "success" : "error"}`,
      message: `${
        response.ok ? "Successfully updated" : "Failed to update"
      } quote note.`,
    });
  };

  const getRowStyle = useCallback((params) => {
    if (params.node.rowPinned) {
      return { fontWeight: "bold" };
    }
  }, []);

  const sendQuoteItemsReport = async (payload) => {
    setIsLoading(true);

    const emailPayload = {
      quoteId: state.quoteId,
      toEmail: payload.emailAddress,
      subject: "Quote Items Report",
      body: "Report attached here",
      attachmentFileName: "Quote Items Report",
    };

    const response = await emailQuoteItemsReport(emailPayload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      showToast({
        type: "error",
        message: data.errorMessage,
      });
      return;
    }

    setIsLoading(false);

    showToast({
      type: `${response.ok ? "success" : "error"}`,
      message: `${
        response.ok ? "Successfully emailed" : "Failed email"
      } quote items report.`,
    });
  };

  const printQuoteItems = async () => {
    const payload = {
      quoteId: state.quoteId,
    };

    const response = await createQuoteItemsReportFile(payload);
    const data = await response.json();

    if (!_.isNil(data.errorMessage)) {
      showToast({
        type: "error",
        message: data.errorMessage,
      });
      return;
    }

    printPreviewReportWithBase64(data.base64);
  };

  const onColumnsSizeChanged = (params) => {
    var gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      let column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  const scrollToLastRow = () => {
    const api = gridRef.current.api;
    const lastRowIndex = api.getDisplayedRowCount() - 1;
    api.ensureIndexVisible(lastRowIndex, "bottom");
  };

  const focusOnLastRow = () => {
    if (gridRef.current) {
      const api = gridRef.current.api;
      const lastRowIndex = api.getDisplayedRowCount() - 1;
      api.ensureIndexVisible(lastRowIndex, "bottom");

      api.startEditingCell({
        rowIndex: lastRowIndex,
        colKey: "Unit Price", // Replace with the actual column key for "unit price"
      });

      // Get the last row node
      const lastRowNode = api.getDisplayedRowAtIndex(lastRowIndex);

      // Deselect all other nodes
      api.deselectAll();

      // Select the last row node
      lastRowNode.setSelected(true);
    }
  };

  return (
    <div>
      <div className="content-header-container d-flex flex-row align-items-center non-printable">
        <div className="me-auto">
          <QuoteContentHeader
            title={editable ? "Edit Quote" : "Quote"}
            updateQuoteStatus={(e) => updateQuoteStatus(e)}
            status={status}
            fillQuoteData={() => fillQuoteData()}
            editable={editable}
          />
        </div>
        <button
          className="btn btn-primary me-2 d-none d-md-block"
          onClick={printQuoteItems}
        >
          Print
        </button>
        <button
          className="btn btn-primary d-none d-md-block"
          onClick={() => setIsOpen(true)}
        >
          Send Email
        </button>
      </div>
      <div className="row non-printable mb-2 d-md-none">
        <div className="col-12">
          <button className="btn btn-primary w-100 disabled">Send Email</button>
        </div>
      </div>
      <div id="printableContent">
        <QuoteDetails
          name={name}
          email={email}
          phone={phone}
          address={address}
          estimateNo={estimateNo}
          estimateName={estimateName}
          estimateDate={estimateDate}
          estimateDueDate={estimateDueDate}
          subtotal={subtotal}
          discount={discount}
          total={total}
          tax={tax}
          invoiceId={invoiceId}
          printMode={printMode}
          toggle={editQuoteToggle}
          editable={editable}
        />
        {editable && (
          <div
            className={`row content-body-container quote-form-container non-printable ${
              printMode ? "d-none" : ""
            }`}
          >
            <div className="col-12">
              <div className="content-section-container">
                <QuoteItemForm
                  itemList={itemList}
                  customerId={customerId}
                  quoteId={state.quoteId}
                  province={province}
                  fillQuoteData={fillQuoteData}
                  fillTableData={fillTableData}
                  gLCodeList={gLCodeList}
                  setSubtotal={setSubtotal}
                  setDiscount={setDiscount}
                  setTotal={setTotal}
                  setTax={setTax}
                  oldValues={oldValues}
                  focusOnLastRow={focusOnLastRow}
                  scrollToLastRow={scrollToLastRow}
                  taxSelectRef={taxSelectRef}
                />
              </div>
            </div>
          </div>
        )}
        <div className="row content-body-container quote-body-container">
          <div className="col-12">
            <div
              id="quoteGrid"
              className="ag-theme-alpine content-section-container shadow-none"
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                onColumnSizeChanged={onColumnsSizeChanged}
                onGridSizeChanged={onGridSizeChanged}
                onCellEditingStopped={onCellEditingStopped}
                rowSelection={"single"}
                getRowStyle={getRowStyle}
                rowHeight={70}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="row content-body-container quote-footer-container">
          <div className="col-12">
            <div className="content-section-container">
              <QuoteDetailFooter
                updateNoteStatus={updateNoteStatus}
                note={note}
                editable={editable}
                subtotal={subtotal}
                discount={discount}
                total={total}
                tax={tax}
                printMode={printMode}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} {...args}>
        <ModalHeader toggle={() => setIsOpen(false)}>Send Email</ModalHeader>
        <ModalBody>
          <form
            className="row form-container"
            id="send-email-form"
            onSubmit={handleSubmit(sendQuoteItemsReport)}
          >
            <div className="col-12 my-3">
              <label htmlFor="emailAddress" className="form-label">
                Email Address
              </label>
              <input
                type="text"
                className="form-control"
                id="emailAddress"
                defaultValue={email}
                {...register("emailAddress", {
                  required: "Email Address is required",
                })}
              />
              <small className="form-error-message">
                {errors?.emailAddress && errors.emailAddress.message}
              </small>
            </div>

            <div className="d-flex flex-row-reverse align-items-center mt-3">
              <button
                className="btn btn-primary ms-2"
                form="send-email-form"
                type="submit"
                disabled={isLoading}
              >
                Send
              </button>
              <button
                className="btn btn-primary"
                disabled={isLoading}
                type="button"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <EditQuoteModal
        modal={editQuoteModal}
        toggle={editQuoteToggle}
        currentQuote={quoteData}
        fillQuoteData={fillQuoteData}
        fillTableData={fillTableData}
      />
    </div>
  );
}

export default EditQuotePage;
