import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import { showToast } from '../../utils/toastUtils';
import ModalRender from '../Commons/ModalRender';
import { isSuperAdmin, isABMUser, isAccountUser, getUserTypeList } from '../../utils/roleUtils';
import { addUser, updateUser } from '../../services/userServices';
import ReactDatePicker from "react-datepicker";
import moment from 'moment';
import { fetchGLCodeFilteredByAccountAndType } from '../../services/glCodeServices';
import { fetchAccounts } from '../../services/accountServices';
import Select from 'react-select'
import { selectStyle } from '../../utils/styleUtils';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { PROVINCE, PAY_FREQUENCY, VACATION_TYPE, PAY_METHOD_TYPE, TD1_PROVINCE, TD2_FEDERAL, TD1_PROVINCE_QC } from '../../constants';
import { fetchPayrollSettingsEmployee } from '../../services/payrollSettingsServices';
import { getAccountIdForEmployee, isValidSIN } from '../../utils/userUtils';
import { removePhoneFormat, removeDollar, removePercentage, removeDays, removeNonDigits } from '../../utils/formatUtils';
import { handlePostalCodeChange, formatPostalCode } from '../../utils/formatUtils';

function EmployeeForm({
  isFormEdit = false,
  selectedUser = {},
  isFormHidden,
  fillUserList,
  toggleFormDisplay,
  setIsFormDirty,
  modal,
  setModal,
  toggle,
  focusOnOpenOrCloseButton
}) {
  ;
  const { control, register, unregister, handleSubmit, setValue, clearErrors, watch, reset, setFocus, setError, formState: { errors, isDirty } } = useForm({
    defaultValues: {
      payroll: {
        payStartDate: isFormEdit ? new Date(selectedUser?.payroll?.payStartDate) || '' : ''
      }
    }
  });
  var options = []
  const userType = useSelector((state) => state.auth.user.userType);
  const accountId = useSelector((state) => state.auth.user.accountId);
  const accountState = useSelector((state) => state.account.selectedAccount);
  const [province, setProvince] = useState("");
  const [tD1Province, setTD1Province] = useState("");
  const [tD2Federal, setTD2Federal] = useState("");
  const [payFrequency, setPayFrequency] = useState("");
  const [vacationType, setVacationType] = useState("");
  const [payMethodType, setPayMethodType] = useState("");
  const [payrollSettings, setPayrollSettings] = useState();
  const [gLCode, setGLCode] = useState("");
  const [payStartDate, setPayStartDate] = useState("");
  const [isPayrollFormHidden, setIsPayrollFormHidden] = useState(true);
  const [accountIdValue, setAccountIdValue] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const [selectedDate, setSelectedDate] = useState()
  // const [cppAndEIDisabled, setCPPAndEIDisabled] = useState(false);
  const [cppDisabled, setCPPDisabled] = useState(false);
  const [eiDisabled, setEIDisabled] = useState(false);
  const checkboxValue = watch('checkbox');
  const vacationTypeWatch = watch("payroll.vacationType")
  const accountIdWatch = watch("accountId");
  const accountIdRef = useRef();

  useEffect(() => {
    if (isSuperAdmin(userType)) {
      setUserTypeList(getUserTypeList(accountState.accountId, accountIdWatch?.value, userType));
      if (accountIdWatch !== undefined) {
        fillPayrollSettings(accountIdWatch?.value)
        fillGLCode(accountIdWatch?.value);
        if (payrollSettings == null) {
          setDefaultFormPayrollValue();
        }
      }
    }

  }, [accountIdWatch])

  useEffect(() => {
    if (isFormEdit) {
      setEmployeeEditData();
    } else {
      setNewEmployeeData();
    }
  }, [selectedUser["accountId"]])

  // useStates
  const [userTypeValue, setUserTypeValue] = useState();
  const [accountList, setAccountList] = useState([]);
  const [gLCodeList, setGLCodeList] = useState([]);
  const [userTypeList, setUserTypeList] = useState([])

  if (accountList !== undefined && isSuperAdmin(userType)) {
    options = accountList.map((account, index) => ({
      value: parseInt(account.accountId),
      label: account.accountName
    }));
  }

  // useEffects
  useEffect(() => {
    clearErrors();
    fillGLCode();
    fillAccounts();
  }, []);

  useEffect(() => {
    clearErrors();
    reset();

    if (isFormHidden) {
      setValue("payrollEmployee", false)
      setIsPayrollFormHidden(true);
    }
    if (!isFormHidden) {
      setUserTypeList(getUserTypeList(accountState.accountId, accountId, userType));

      setTimeout(() => {
        if (isSuperAdmin(userType)) {
          accountIdRef.current.focus()

        } else {
          setFocus("userType");
        }

      }, 50);
    }
  }, [isFormHidden]);

  // @TODO: Fix for super admin. Not tested with a super admin account yet
  useEffect(() => {
    if (!isSuperAdmin(userType)) {
      fillPayrollSettings(selectedUserAsABM() ? accountState.accountId : accountId);
    }
  }, [])

  useEffect(() => {
    setIsFormDirty(isDirty)
  }, [isDirty])

  useEffect(() => {
    if (!isPayrollFormHidden && !isFormEdit) {
      if (payrollSettings !== undefined || selectedUser.payroll === null) {
        setDefaultFormPayrollValue()
      } else {
        setTD1Province("");
        setTD2Federal("");
        setPayFrequency("");
        setVacationType("");
        setPayMethodType("");
        setGLCode("");
      }
    }

    if (!isPayrollFormHidden && payrollSettings === undefined && selectedUser.payroll === null) {
      setTD1Province(1);
      setTD2Federal(1);
      setValue("payroll.tD1Province", 1);
      setValue("payroll.tD2Federal", 1);
    }
  }, [isPayrollFormHidden])

  const fillGLCode = async (currentAccountId = accountId) => {
    let response = null;
    if (isABMUser(userType)) {
      response = await fetchGLCodeFilteredByAccountAndType(accountState.accountId !== null ? accountState.accountId : currentAccountId, 5);
    } else {
      response = await fetchGLCodeFilteredByAccountAndType(currentAccountId, 5);
    }
    setGLCodeList(response);
  }

  const fillAccounts = async () => {
    let response = null

    if (isSuperAdmin(userType) || isABMUser(userType)) {
      response = await fetchAccounts();
    }
    setAccountList(response);
  }

  const fillPayrollSettings = async (id) => {
    const response = await fetchPayrollSettingsEmployee(id, setPayrollSettings)

    setPayrollSettings(response)
  }

  const getActiveDefaultValue = () => {
    if (selectedUser.length <= 0) {
      return 'true';
    }

    return selectedUser.active ? 'true' : '';
  }

  const onPayrollEmployeeCheckboxChange = (event) => {
    setIsPayrollFormHidden(!isPayrollFormHidden);
    if (!isPayrollFormHidden === true) {
      unregister("payroll");
    }
  }

  // useEffect(() => {
  //   if (cppDisabled) {
  //     console.log("CPP is disabled");
  //   } else {
  //     console.log("CPP is enabled");
  //   }
  // }, [cppDisabled]);

  // useEffect(() => {
  //   // Logic to handle changes for eiDisabled
  //   if (eiDisabled) {
  //     console.log("EI is disabled");
  //   } else {
  //     console.log("EI is enabled");
  //   }
  // }, [eiDisabled]);

  const onSubmit = async (payload) => {

    // Remove extra spaces at the start and in the end
    _.forOwn(payload, (value, key) => {
      if (typeof value === 'string') {
        payload[key] = value.trim();
      }
    });

    if (payload.postalCode) {
      payload.postalCode = payload.postalCode.replace(/\s+/g, ''); // Remove all spaces
    }

    let response = null;
    setIsLoading(true);

    var dateOfBirth = moment(payload.dateOfBirth).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
    _.set(payload, 'dateOfBirth', dateOfBirth);


    if (!_.isNil(payload.payroll.sin) && !isValidSIN(payload.payroll.sin)) {
      setError('payroll.sin', {
        type: 'manual',
        message: 'SIN Number is Invalid',
      });
      setIsLoading(false);
      return;
    }

    _.set(payload, 'phone', removePhoneFormat(payload.phone));
    _.set(payload, 'cell', removePhoneFormat(payload.cell));

    // Remove field masking
    if (!isPayrollFormHidden) {
      _.set(payload, 'payroll.SINNumber', removeNonDigits(payload.payroll.sin) || null);
      _.set(payload, 'payroll.payPerUnit', parseFloat(removeDollar(payload.payroll.payPerUnit.toString())));
      _.set(payload, 'payroll.overtimePaymentRate', parseFloat(removePercentage(payload.payroll.overtimePaymentRate.toString())));
      if (payload.payroll.vacationType === 0) {
        _.set(payload, 'payroll.vacationAmount', parseFloat(removePercentage(payload.payroll.vacationAmount.toString())));
      } else {
        _.set(payload, 'payroll.vacationAmount', parseFloat(removeDays(payload.payroll.vacationAmount.toString())));
      }
    }

    const payloadAccountId = payload.accountId;
    _.set(payload, 'accountId', getAccountIdForEmployee(payloadAccountId, accountState.accountId, accountId, userType));
    _.set(payload, 'payroll.accountId', getAccountIdForEmployee(payloadAccountId, accountState.accountId, accountId, userType));
    _.set(payload, 'payroll.payrollPaidContribution.accountId', getAccountIdForEmployee(payloadAccountId, accountState.accountId, accountId, userType));

    // Existing logic for form submission continues...
    if (isFormEdit) {
      // for editing employee
      _.set(payload, 'userId', selectedUser.userId);

      if (!isPayrollFormHidden) {
        _.set(payload, 'payroll.payrollId', selectedUser.payroll?.payrollId === undefined ? 0 : selectedUser.payroll?.payrollId);
        _.set(payload, 'payroll.payrollEmployee', true);
        var payStartDate = moment(payload.payroll.payStartDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
        _.set(payload, 'payroll.payStartDate', payStartDate);
        var paidContributionDate = moment(payload.payroll.payrollPaidContribution.date).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
        _.set(payload, 'payroll.payrollPaidContribution', [{
          accountId: accountId,
          payrollPaidContributionId: 0,
          previouslyPaidCPP: parseFloat(removeDollar(payload.payroll.payrollPaidContribution.previouslyPaidCPP.toString())),
          previouslyPaidEI: parseFloat(removeDollar(payload.payroll.payrollPaidContribution.previouslyPaidEI.toString())),
          date: paidContributionDate,
          createdTimeStamp: new Date(),
          lastModifiedTimeStamp: new Date()
        }]);

      } else {
        _.set(payload, 'payroll', null);
      }

      if (isAccountUser(userType)) {
        _.set(payload, 'userType', selectedUser.userType);
      }

      response = await updateUser(payload);
      setIsLoading(false);
      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully updated' : 'Failed to update'} user.`
      });
    } else {
      // for adding employee
      _.set(payload, 'userId', 0);
      if (!isPayrollFormHidden) {
        _.set(payload, 'payroll.payrollId', 0);

        _.set(payload, 'payroll.payrollPaidContribution.payrollPaidContributionId', 0);
        var payStartDate = moment(payload.payroll.payStartDate).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
        _.set(payload, 'payroll.payStartDate', payStartDate);
        var paidContributionDate = moment(payload.payroll.payrollPaidContribution.date).clone().hour(7).minute(0).second(0).format('YYYY-MM-DD');
        // _.set(payload, 'payroll.payrollPaidContribution.Date', paidContributionDate);

        _.set(payload, 'payroll.payrollPaidContribution', [{
          accountId: accountId,
          payrollPaidContributionId: 0,
          previouslyPaidCPP: parseFloat(removeDollar(payload.payroll.payrollPaidContribution.previouslyPaidCPP.toString())),
          previouslyPaidEI: parseFloat(removeDollar(payload.payroll.payrollPaidContribution.previouslyPaidEI.toString())),
          date: paidContributionDate,
          createdTimeStamp: new Date(),
          lastModifiedTimeStamp: new Date()
        }]);
      } else {
        _.set(payload, 'payroll', null);
      }

      if (isAccountUser(userType)) {
        _.set(payload, 'userType', 4);
      }

      response = await addUser(payload);
      setIsLoading(false);
      let errorMessage = await response.text();

      if (errorMessage === 'The user already exists.') {
        errorMessage = 'This email already exists. Please use a different email.'
      }

      showToast({
        type: `${response.ok ? 'success' : 'error'}`,
        message: `${response.ok ? 'Successfully added user' : errorMessage}`
      });
    }

    if (response.ok) {
      fillUserList();
      toggleFormDisplay();

      if (isDirty) {
        setModal(false)
      }
      setIsFormDirty(false)
      setIsPayrollFormHidden(true);
      setValue("payrollEmployee", false);
      setValue("payroll.payStartDate", "");
      setValue("payroll.payPerUnit", "");
      setValue("phone", "");
      setValue("cell", "");
      setValue("province", "");
      setValue("dateOfBirth", "");
      setValue("payroll.payrollPaidContribution.date", new Date());
      setProvince("");
      setCPPDisabled(false);
      setEIDisabled(false);
      focusOnOpenOrCloseButton()
    }
  };

  const setDefaultFormPayrollValue = () => {
    setValue("payroll.overtimePaymentRate", (payrollSettings?.overtimePaymentRate * 100))
    setValue("payroll.tD1Province", payrollSettings?.tD1Province)
    setValue("payroll.tD2Federal", payrollSettings?.tD2Federal)
    setValue("payroll.payFrequency", payrollSettings?.payFrequency)
    setValue("payroll.vacationType", payrollSettings?.vacationType)
    setValue("payroll.payMethodType", payrollSettings?.payMethodType)
    setValue("payroll.glCodeId", payrollSettings?.glCodeId)
    setValue("payroll.payPerUnit", "")
    setValue("payroll.payStartDate", "")
    setTD1Province(payrollSettings?.tD1Province);
    setTD2Federal(payrollSettings?.tD2Federal);
    setPayFrequency(payrollSettings?.payFrequency);
    setVacationType(payrollSettings?.vacationType);
    setPayMethodType(payrollSettings?.payMethodType);
    setGLCode(payrollSettings?.glCodeId);
    // setCPPAndEIDisabled(payrollSettings?.cppAndEIDisabled);
    setCPPDisabled(payrollSettings?.cppDisabled);
    setEIDisabled(payrollSettings?.eiDisabled);
    setValue("payroll.payrollPaidContribution.previouslyPaidCPP", 0);
    setValue("payroll.payrollPaidContribution.previouslyPaidEI", 0);
    setValue("payroll.payrollPaidContribution.date", new Date());

  }

  const setEmployeeEditData = () => {
    const accId = {
      value: parseInt(selectedUser.account['accountId']),
      label: selectedUser.account['accountName']
    }


    setProvince(parseInt(selectedUser['province']));
    setUserTypeValue(parseInt(selectedUser["userType"]))
    setValue("province", parseInt(selectedUser['province']));
    setValue("userType", parseInt(selectedUser['userType']));
    setValue("phone", selectedUser['phone']);
    setValue("cell", selectedUser['cell']);
    setValue("dateOfBirth", moment(selectedUser['dateOfBirth']).hour(7).minute(0).second(0).toDate());
    // Set payroll information if the employee is under payroll
    if (selectedUser.payroll != null) {

      setTD1Province(parseInt(selectedUser.payroll['tD1Province']));
      setTD2Federal(parseInt(selectedUser.payroll['tD2Federal']));
      setGLCode(parseInt(selectedUser.payroll['glCodeId']))
      setPayFrequency(parseInt(selectedUser.payroll['payFrequency']));
      setVacationType(parseInt(selectedUser.payroll['vacationType']));
      // setCPPAndEIDisabled(selectedUser.payroll['cppDisabled']);
      setCPPDisabled(selectedUser.payroll['cppDisabled']);
      setEIDisabled(selectedUser.payroll['eiDisabled']);
      setPayMethodType(parseInt(selectedUser.payroll['payMethodType']));
      setPayStartDate(moment(selectedUser.payroll['payStartDate']).hour(7).minute(0).second(0).toDate());
      setValue("payroll.tD1Province", selectedUser.payroll['tD1Province']);
      setValue("payroll.tD2Federal", selectedUser.payroll['tD2Federal']);
      setValue("payroll.glCodeId", selectedUser.payroll['glCodeId']);
      setValue("payroll.payFrequency", selectedUser.payroll['payFrequency']);
      setValue("payroll.vacationType", selectedUser.payroll['vacationType']);
      setValue("payroll.payMethodType", selectedUser.payroll['payMethodType']);
      setValue("payroll.payPerUnit", selectedUser.payroll['payPerUnit']);
      setValue("payroll.overtimePaymentRate", selectedUser.payroll['overtimePaymentRate']);
      setValue("payroll.vacationAmount", selectedUser.payroll['vacationAmount']);
      setValue("payroll.cppDisabled", selectedUser.payroll['cppDisabled']);
      setValue("payroll.eiDisabled", selectedUser.payroll['eiDisabled']);
      setValue("checkbox.cppDisabled", selectedUser.payroll['cppDisabled']);
      setValue("checkbox.eiDisabled", selectedUser.payroll['eiDisabled']);
      setValue("payroll.payStartDate", moment(selectedUser.payroll['payStartDate']).hour(7).minute(0).second(0).toDate());
      setValue("payroll.sin", selectedUser.payroll['sinNumber']);
      if (selectedUser?.payroll?.payrollPaidContribution.length > 0) {
        setIsFieldDisabled(true);
        setPayStartDate(moment(selectedUser?.payroll?.payrollPaidContribution[0]['date']).hour(7).minute(0).second(0).toDate());
        setValue("payroll.payrollPaidContribution.previouslyPaidCPP", selectedUser?.payroll?.payrollPaidContribution[0]['previouslyPaidCPP']);
        setValue("payroll.payrollPaidContribution.previouslyPaidEI", selectedUser?.payroll?.payrollPaidContribution[0]['previouslyPaidEI']);
        setValue("payroll.payrollPaidContribution.date", moment(selectedUser?.payroll?.payrollPaidContribution[0]['date']).hour(7).minute(0).second(0).toDate());
      } else {
        setIsFieldDisabled(false);
        setValue("payroll.payrollPaidContribution.previouslyPaidCPP", 0);
        setValue("payroll.payrollPaidContribution.previouslyPaidEI", 0);
        setValue("payroll.payrollPaidContribution.date", "");
        setDefaultFormPayrollValue();
      }
    } else {
      setDefaultFormPayrollValue()
    }
    setValue("active", selectedUser['active']);
    setValue("payrollEmployee", selectedUser['payrollEmployee']);
    setIsPayrollFormHidden(!selectedUser['payrollEmployee'])
    setValue("accountId", accId)
    setAccountIdValue(accId)
  }

  const setNewEmployeeData = () => {
    reset();
    setIsFieldDisabled(false);
    setValue("payrollEmployee", false);
    setIsPayrollFormHidden(true);
    if (payrollSettings != null) {
      setDefaultFormPayrollValue();
    }
    else {
      setValue("vacationAmount", "");
      setValue("phone", "");
      setValue("cell", "")
      setValue("payroll.payrollPaidContribution.previouslyPaidCPP", 0);
      setValue("payroll.payrollPaidContribution.previouslyPaidEI", 0);
    }
    setValue("payroll.payrollPaidContribution.date", new Date())
  }

  const selectedUserAsABM = () => {
    return (isABMUser(userType) && accountState.accountId !== null)
  }


  const getProvinceClaimCodes = () => {
    if (province === 10 || province === "10") {
      return TD1_PROVINCE_QC;
    }

    return TD1_PROVINCE;
  }

  // // Set fields based on checkbox value
  // useEffect(() => {
  //   setValue("payroll.cppDisabled", checkboxValue);
  //   setValue("payroll.eiDisabled", checkboxValue);
  // }, [checkboxValue, setValue]);



  return (
    <div className='content-section-container'>
      <div className='d-flex flex-row align-items-center mb-4'>
        <div className='form-title me-auto'>
          {`${isFormEdit ? 'Update' : 'Add'} Employee`}
        </div>
        <button className="btn btn-primary" form='account-form' type="submit" disabled={isLoading} tabIndex={32}>Save</button>
      </div>
      <form className="row form-container" id="account-form" onSubmit={handleSubmit(onSubmit)}>
        {(isSuperAdmin(userType)) &&
          (<div className="col-6 col-sm-4 mb-2">
            <label htmlFor="accountId" className="form-label">Account</label>
            <div className="custom-selector">
              <Controller
                name="accountId"
                control={control}
                rules={{ required: "Account is required" }}
                render={({ field }) => (
                  <Select
                    onChange={field.onChange}
                    value={field.value}
                    ref={accountIdRef}
                    options={options}
                    className="expense-select"
                    placeholder={'Enter account'}
                    defaultValue={accountIdValue}
                    id="accountId"
                    styles={selectStyle}
                    isClearable
                    tabIndex={1}
                  />
                )}
              />
            </div>
            <small className='form-error-message'>
              {errors?.accountId && errors.accountId?.message}
            </small>
          </div>)
        }
        {(!isAccountUser(userType)) &&
          (<div className="col-6 col-sm-4 mb-2">
            <label htmlFor="userType" className="form-label">User Type:</label>
            <select className="form-select" id="userType" value={userTypeValue} tabIndex={2} {...register("userType", { valueAsNumber: true, onChange: (e) => setUserTypeValue(e.target.value), required: "User Type is required" })}>
              <option key="none" value="" hidden>User Type</option>
              {userTypeList.map((userType, index) =>
                <option key={userType.key} value={userType.key}>{userType.value}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.userType && errors.userType.message}
            </small>
          </div>)}

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="firstName" className="form-label">First Name:</label>
          <input type="text" className="form-control" id="firstName" tabIndex={3}
            defaultValue={isFormEdit ? selectedUser?.firstName || '' : ''}
            {...register("firstName", { required: "First name is required" })}
          />
          <small className='form-error-message'>
            {errors?.firstName && errors.firstName.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="lastName" className="form-label">Last Name:</label>
          <input type="text" className="form-control" id="lastName" tabIndex={4}
            defaultValue={isFormEdit ? selectedUser?.lastName || '' : ''}
            {...register("lastName", { required: "Last name is required" })}
          />
          <small className='form-error-message'>
            {errors?.lastName && errors.lastName.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="email" className="form-label">Email:</label>
          <input type="text" className="form-control" id="email" tabIndex={5}
            defaultValue={isFormEdit ? selectedUser?.email || '' : ''}
            autoComplete='on'
            {...register("email", { required: "Email is required" })}
          />
          <small className='form-error-message'>
            {errors?.email && errors.email.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="dateOfBirth" className="form-label">Date of Birth:</label>
          <Controller
            control={control}
            name='dateOfBirth'
            register={register}
            rules={{ required: "Date of Birth is required" }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <ReactDatePicker
                className='datepicker-field'
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                dateFormat='yyyy-MM-dd'
                id="dateOfBirth"
                tabIndex={6}
              />
            )}
          />
          <small className='form-error-message'>
            {errors?.dateOfBirth && errors?.dateOfBirth.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="phone" className="form-label">Phone</label>
          <Controller
            control={control}
            name='phone'
            register={register}
            rules={{ required: "Phone is required" }}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <PatternFormat value={value === undefined ? '' : value} onChange={onChange} onBlur={onBlur} tabIndex={7} className='form-control' format="(###) ###-####" id="phone" mask="_" allowEmptyFormatting autoComplete='on' />
            )}
          />
          <small className='form-error-message'>
            {errors?.phone && errors.phone.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="mobile" className="form-label">Mobile</label>
          <Controller
            control={control}
            name='cell'
            register={register}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <PatternFormat name={name} value={value === undefined ? '' : value} onChange={onChange} onBlur={onBlur} tabIndex={8} className='form-control' format="(###) ###-####" id="mobile" mask="_" allowEmptyFormatting />
            )}
          />
          <small className='form-error-message'>
            {errors?.cell && errors.cell.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="notes" className="form-label">Notes</label>
          <input type="text" className="form-control" id="notes" tabIndex={9}
            defaultValue={isFormEdit ? selectedUser?.notes || '' : ''}
            {...register("notes")}
          />
          <small className='form-error-message'>
            {errors?.notes && errors.notes.message}
          </small>
        </div>

        <div className="col-12 my-2">
          <hr></hr>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="street" className="form-label">Street:</label>
          <input type="text" className="form-control" id="street" tabIndex={10}
            defaultValue={isFormEdit ? selectedUser?.street || '' : ''}
            {...register("street", { required: "Street is required" })}
          />
          <small className='form-error-message'>
            {errors?.street && errors.street.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="city" className="form-label">City:</label>
          <input type="text" className="form-control" id="city" tabIndex={11}
            defaultValue={isFormEdit ? selectedUser?.city || '' : ''}
            {...register("city", { required: "City is required" })}
          />
          <small className='form-error-message'>
            {errors?.city && errors.city.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="province" className="form-label">Province:</label>
          <select className="form-select" id="province" value={province} tabIndex={12} {...register("province", { valueAsNumber: true, onChange: (e) => setProvince(e.target.value), required: "Province is required" })}>
            <option key="none" value="" hidden>Province</option>
            {PROVINCE.map((prov, index) =>
              <option key={index} value={prov.value}>{prov.label}</option>
            )}
          </select>
          <small className='form-error-message'>
            {errors?.province && errors.province.message}
          </small>
        </div>

        <div className="col-6 col-sm-4 mb-2">
          <label htmlFor="postalCode" className="form-label">Postal Code</label>
          <input
            type="text"
            className="form-control"
            id="postalCode"
            tabIndex={13}
            defaultValue={isFormEdit ? formatPostalCode(selectedUser?.postalCode || '') : ''}
            {...register("postalCode", { required: "Postal Code is required", maxLength: 7 })}
            onChange={handlePostalCodeChange}
          />
          <small className="form-error-message">
            {errors?.postalCode && errors.postalCode.message}
          </small>
        </div>
        
        <div>
        </div>
        <div className={`row col-12 ${!isPayrollFormHidden ? '' : 'd-none'}`}>
          <div className="col-12 my-2">
            <hr></hr>
          </div>
          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="tD1Province" className="form-label">TD1 Province:</label>
            <select className="form-select" id="tD1Province" value={tD1Province} tabIndex={14} {...register("payroll.tD1Province", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setTD1Province(e.target.value), required: "TD1 Province is required" })}>
              <option key="none" value="" hidden>TD1 Prov</option>
              {getProvinceClaimCodes().map((td1, index) =>
                <option key={index} value={td1.value}>{td1.label}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.payroll?.tD1Province && errors.payroll?.tD1Province.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="tD2Federal" className="form-label">TD2 Federal:</label>
            <select className="form-select" id="tD2Federal" value={tD2Federal} tabIndex={15} {...register("payroll.tD2Federal", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setTD2Federal(e.target.value), required: "TD2 Federal is required" })}>
              <option key="none" value="" hidden>TD1 Fed</option>
              {TD2_FEDERAL.map((td2, index) =>
                <option key={index} value={td2.value}>{td2.label}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.payroll?.tD2Federal && errors.payroll?.tD2Federal.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="payFrequency" className="form-label">Pay Frequency:</label>
            <select className="form-select" id="payFrequency" value={payFrequency} tabIndex={16} disabled={!(_.isEmpty(payrollSettings) && _.isEmpty(selectedUser))} {...register("payroll.payFrequency", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setPayFrequency(e.target.value), required: "Pay frequency is required" })}>
              <option key="none" value="" hidden>Pay Frequency</option>
              {PAY_FREQUENCY.map((payFrequency, index) =>
                <option key={index} value={payFrequency.value}>{payFrequency.label}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.payroll?.payFrequency && errors.payroll?.payFrequency.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="payStartDate" className="form-label">Pay Start Date:</label>
            <Controller
              control={control}
              name='payroll.payStartDate'
              register={register}
              rules={!isPayrollFormHidden && { required: "Pay start date is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className='datepicker-field'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  dateFormat='yyyy-MM-dd'
                  id="payStartDate"
                  tabIndex={17}
                />
              )}
            />
            <small className='form-error-message'>
              {errors?.payroll?.payStartDate && errors.payroll?.payStartDate.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="vacationType" className="form-label">Vacation Type:</label>
            <select className="form-select" id="vacationType" value={vacationType} tabIndex={18} {...register("payroll.vacationType", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setVacationType(e.target.value), required: "Vacation type is required" })}>
              <option key="none" value="" hidden>Vacation Type</option>
              {VACATION_TYPE.map((vacationType, index) =>
                <option key={index} value={vacationType.value}>{vacationType.label}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.payroll?.vacationType && errors.payroll?.vacationType.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="vacationAmount" className="form-label">Vacation Amount:</label>
            <Controller
              control={control}
              name='payroll.vacationAmount'
              register={register}
              rules={!isPayrollFormHidden && { required: "Vacation Amount is required" }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} tabIndex={19} className='form-control' suffix={`${vacationTypeWatch === 0 ? '%' : ' days'}`} thousandsGroupStyle="thousand" thousandSeparator="," id="vacationAmount" mask="_" />
              )}
            />
            <small className='form-error-message'>
              {errors?.payroll?.vacationAmount && errors.payroll?.vacationAmount.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="payMethodType" className="form-label">Pay Method Type:</label>
            <select className="form-select" id="payMethodType" value={payMethodType} tabIndex={20} {...register("payroll.payMethodType", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setPayMethodType(e.target.value), required: "Pay method type is required" })}>
              <option key="none" value="" hidden>Pay Method Type</option>
              {PAY_METHOD_TYPE.filter(method => method.value !== 4).map((payMethodType, index) =>
                <option key={index} value={payMethodType.value}>{payMethodType.label}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.payroll?.payMethodType && errors.payroll?.payMethodType.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="payPerUnit" className="form-label">Pay Per Unit:</label>
            <Controller
              control={control}
              name='payroll.payPerUnit'
              register={register}
              rules={!isPayrollFormHidden && { required: "Pay per unit is required" }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} tabIndex={21} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="payPerUnit" mask="_" fixedDecimalScale />
              )}
            />
            <small className='form-error-message'>
              {errors?.payroll?.payPerUnit && errors.payroll?.payPerUnit.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="overtimePaymentRate" className="form-label">Overtime Payment Rate:</label>
            <Controller
              control={control}
              name='payroll.overtimePaymentRate'
              register={register}
              rules={!isPayrollFormHidden && { required: "Overtime payment rate is required" }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} tabIndex={22} className='form-control' suffix="%" thousandsGroupStyle="thousand" thousandSeparator="," id="overtimePaymentRate" mask="_" />
              )}
            />
            <small className='form-error-message'>
              {errors?.payroll?.overtimePaymentRate && errors.payroll?.overtimePaymentRate.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="glCodeId" className="form-label">GL Code:</label>
            <select className="form-select" id="glCodeId" value={gLCode} tabIndex={23} {...register("payroll.glCodeId", !isPayrollFormHidden && { valueAsNumber: true, onChange: (e) => setGLCode(e.target.value), required: !isPayrollFormHidden })}>
              <option key="none" value="" hidden>GL Code</option>
              {gLCodeList.map((gl, index) =>
                <option key={index} value={gl.glCodeId}>{gl.glCodeName} - {gl.description}</option>
              )}
            </select>
            <small className='form-error-message'>
              {errors?.payroll?.glCodeId && errors.payroll?.glCodeId.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="payroll-sin" className="form-label">SIN Number</label>
            <Controller
              control={control}
              name='payroll.sin'
              register={register}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <PatternFormat value={value === undefined ? '' : value} onChange={onChange} onBlur={onBlur} tabIndex={24} className='form-control' format="###-###-###" id="payroll-sin" mask="_" allowEmptyFormatting autoComplete='on' />
              )}
            />
            <small className='form-error-message'>
              {errors?.payroll?.sin && errors.payroll?.sin.message}
            </small>
          </div>


          <div className="col-6 col-sm-4 mb-2 mt-3">
            {/* Checkbox for Disable CPP */}
            <div className="form-check d-flex align-items-center mb-2">
              <input
                className="form-check-input me-2"
                type="checkbox"
                id="disableCpp"
                tabIndex={25}
                {...register('payroll.cppDisabled')}
              />
              <label
                className="form-check-label"
                htmlFor="disableCpp"
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  marginTop: '3px',
                  lineHeight: '3',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Disable CPP
              </label>
            </div>
            {/* Display error message for Disable CPP */}
            <small className="form-error-message">
              {errors?.disableCpp && errors.disableCpp.message}
            </small>

            {/* Checkbox for Disable EI */}
            <div className="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input me-2"
                type="checkbox"
                id="disableEi"
                tabIndex={26}
                {...register('payroll.eiDisabled')}
              />
              <label
                className="form-check-label"
                htmlFor="disableEi"
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  marginTop: '3px',
                  lineHeight: '3',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Disable EI
              </label>
            </div>
            {/* Display error message for Disable EI */}
            <small className="form-error-message">
              {errors?.disableEi && errors.disableEi.message}
            </small>
          </div>

          {/* <div>
        <label>Toggle both hidden fields with this checkbox:</label>
        <input type="checkbox" {...register('checkbox')} />
      </div> */}

          {/* Hidden fieldOne and fieldTwo */}
          {/* <input
            type="checkbox"
            {...register('payroll.cppDisabled')}
            style={{ display: 'none' }}
          />
          <input
            type="checkbox"
            {...register('payroll.eiDisabled')}
            style={{ display: 'none' }}
          /> */}

          <div className="col-12 my-2">
            <hr></hr>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="previouslyPaidCPP" className="form-label">Previously Paid CPP:</label>
            <Controller
              control={control}
              name='payroll.payrollPaidContribution.previouslyPaidCPP'
              register={register}
              rules={!isPayrollFormHidden && { required: "Previously paid CPP is required" }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} tabIndex={27} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="previouslyPaidCPP" mask="_" fixedDecimalScale disabled={isFieldDisabled} />
              )}
            />
            <small className='form-error-message'>
              {errors?.payroll?.payrollPaidContribution?.previouslyPaidCPP && errors.payroll?.payrollPaidContribution?.previouslyPaidCPP.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="previouslyPaidEI" className="form-label">Previously Paid EI:</label>
            <Controller
              control={control}
              name='payroll.payrollPaidContribution.previouslyPaidEI'
              register={register}
              rules={!isPayrollFormHidden && { required: "Previously paid EI is required" }}
              render={({ field: { onChange, onBlur, name, value, ref } }) => (
                <NumericFormat name={name} value={value} onChange={onChange} onBlur={onBlur} tabIndex={28} className='form-control' prefix="$" thousandsGroupStyle="thousand" thousandSeparator="," id="previouslyPaidEI" mask="_" fixedDecimalScale disabled={isFieldDisabled} />
              )}
            />
            <small className='form-error-message'>
              {errors?.payroll?.payrollPaidContribution?.previouslyPaidEI && errors.payroll?.payrollPaidContribution?.previouslyPaidEI.message}
            </small>
          </div>

          <div className="col-6 col-sm-4 mb-2">
            <label htmlFor="contributionYear" className="form-label">Year Paid:</label>
            <Controller
              control={control}
              name='payroll.payrollPaidContribution.date'
              register={register}
              rules={!isPayrollFormHidden && { required: "Year paid is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ReactDatePicker
                  className='datepicker-field'
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  dateFormat="yyyy"
                  showYearPicker
                  yearDropdownItemNumber={5}
                  scrollableYearDropdown
                  id="contributionYear"
                  dropdownMode="select"
                  disabled={isFieldDisabled}
                  tabIndex={29}
                />
              )}
            />
            <small className='form-error-message'>
              {errors?.payroll?.payStartDate && errors.payroll?.payStartDate.message}
            </small>
          </div>
          <div className='col-12'>
            <p className='text-link' onClick={() => window.open('/claim-codes', '_blank')}>Claim Codes Values</p>
          </div>
        </div>
        <div className="row">
          {!isFormEdit || selectedUser?.payrollEmployee !== true ?
            <div className="col-10 mb-2 mt-3">
              <div className="form-check">
                <input className="form-check-input align-middle" type="checkbox" value="" id="payrollEmployee" tabIndex={30}
                  {...register("payrollEmployee", { onChange: (event) => onPayrollEmployeeCheckboxChange(event) })}
                />
                <label className="form-check-label align-middle " htmlFor="payrollEmployee">Payroll Employee</label>
              </div>
            </div>
            :
            <div className="col-10 mb-2 mt-3"></div>
          }
          {
            (!isAccountUser(userType)) &&
            <div className="col-2 mb-2 mt-3">
              <div className="form-check align-self-end">
                <input className="form-check-input align-middle" type="checkbox" value="" id="active" tabIndex={31}
                  defaultChecked={getActiveDefaultValue}
                  {...register("active")}
                />
                <label className="form-check-label align-middle " htmlFor="active"> Active</label>
                <small className='form-error-message'>
                  {errors?.active && errors.active.message}
                </small>
              </div>
            </div>
          }
        </div>
      </form>
      <ModalRender modal={modal} handleSubmit={handleSubmit} onSubmit={onSubmit} setModal={setModal} toggle={toggle} isFormEdit={isFormEdit} formType={"account"} toggleFormDisplay={toggleFormDisplay} />
    </div>
  )
}

export default EmployeeForm